const UserProfileOrganism = (theme) => ({
  content: {
    padding: `${theme.spacing(8)} 0 0 0`,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  field: {},
  fieldWrapper: {
    width: 424,
    flexShrink: 1,
    overflow: 'visible',
    '& $field': {
      width: 424,
      marginTop: 0,
    },
    '& $field ~ $field': {
      marginTop: theme.spacing(5),
    },
    '& $fieldInnerWrapper': {
      padding: [[theme.spacing(1), 0]],
    },
  },
  modalInnerTitle: {
    marginBottom: theme.spacing(2),
  },
  callout: {
    marginBottom: 40,
    '& strong': {
      ...theme.fontWeights.Bold,
    },
  },
  calloutButton: {
    marginTop: 20,
  },
});

export { UserProfileOrganism as default };
