export const ADD_MESSAGE = 'ADD_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const SHOW_ERROR_DIALOG = 'SHOW_ERROR_DIALOG';
export const HIDE_ERROR_DIALOG = 'HIDE_ERROR_DIALOG';

export const addMessage = ({ variant = 'error', message }) => {
  return {
    type: ADD_MESSAGE,
    payload: {
      message,
      options: {
        variant,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
      },
      key: Date.now() + Math.random(),
    },
  };
};

export const removeMessage = ({ key }) => {
  return {
    type: REMOVE_MESSAGE,
    payload: {
      key,
    },
  };
};
export const showErrorDialog = (errorCode, messages = []) => {
  return {
    type: SHOW_ERROR_DIALOG,
    payload: {
      open: true,
      errorCode,
      messages,
    },
  };
};

export const hideErrorDialog = () => ({
  type: HIDE_ERROR_DIALOG,
});
