import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Header } from 'view/components/header';
import {
  ContentContainerTemplate as ContentContainer,
  PageTemplate,
  PageContent,
  SubHeader,
} from '@control-tower/aerq-ui-library';
import { useTranslation } from 'react-i18next';
import DesignSystem from 'theme/design_system/designSystem';

const useStyles = DesignSystem.molecules.UserActivationErrorPage();

const UserActivationErrorPage = ({ errorCode }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const data = useMemo(() => {
    const title = t(`user.activation.errors.${errorCode}.title`);
    const subTitle = t(`user.activation.errors.${errorCode}.subtitle`);

    return {
      title,
      subTitle,
    };
  }, [t, errorCode]);

  return (
    <PageTemplate>
      <Header showMenusAndOrg={false} showProfileMenu={false} />
      <PageContent>
        <ContentContainer>
          <SubHeader className={classes.root} title={data.title} text={data.subTitle} />
        </ContentContainer>
      </PageContent>
    </PageTemplate>
  );
};

UserActivationErrorPage.propTypes = {
  errorCode: PropTypes.string.isRequired,
};

export { UserActivationErrorPage as default };
