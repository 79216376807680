import { useCallback } from 'react';
import { useNavigate as useNavigateRRD } from 'react-router-dom';

const useNavigate = () => {
  const navigate = useNavigateRRD();

  const navigateTo = useCallback(
    /**
     * @param {'push' | 'replace'} type
     * @param {import('history').To} path
     */
    (type, path) => {
      navigate(path, { replace: type !== 'push' });
    },
    [navigate]
  );

  return navigateTo;
};

export default useNavigate;
