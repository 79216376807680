/**
 *
 * @param {string} path eg.: /home/:urlParam
 * @param {string} url
 * @param {object} options
 * @param {boolean} args.options.ignoreCase
 *
 * @returns {boolean}
 */
const matchesPath = (path, url, options = { ignoreCase: true }) => {
  if (!path || !url) {
    return false;
  }

  const pattern = `^${path.replace('/', '\\/').replace(/:\w+/, '[\\w-]+')}$`;
  const flags = [options.ignoreCase && 'i', 'm'].filter(Boolean).join('');

  return !!url.match(new RegExp(pattern, flags));
};

export default matchesPath;
