import makeStyles from '@mui/styles/makeStyles';
import * as Atoms from './atoms';
import * as Molecules from './molecules';
import * as Organisms from './organisms';

/**
 * remaps an objects values to makeStyles callbacks
 * @template {object} T
 * @param {T} modules
 * @returns {Record<keyof T, () => ReturnType<makeStyles>}
 */
const transfromModule = (modules) => {
  return Object.entries(modules).reduce(
    (result, [name, styleSheet]) => ({ ...result, [name]: () => makeStyles(styleSheet, { name }) }),
    {}
  );
};

const DesignSystem = {
  templates: {},
  pages: {},

  atoms: {
    ...transfromModule(Atoms),
  },
  molecules: {
    ...transfromModule(Molecules),
  },
  organisms: {
    ...transfromModule(Organisms),
  },
};

export default DesignSystem;
