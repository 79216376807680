const AddTrustedSupplierModalMolecule = (theme) => ({
  modal: {
    '& .modal-container': {
      marginTop: 0,
    },
  },
  root: {
    width: 445,
  },
  subtitle: {
    marginTop: theme.spacing(6),
  },
  field: {
    marginTop: theme.spacing(3),
  },
  buttonWrapper: {
    marginTop: theme.spacing(7),
    '& > *': {
      marginRight: theme.spacing(2),
    },
  },
});

export { AddTrustedSupplierModalMolecule as default };
