import PropTypes from 'prop-types';
import { createContext, useContext, useReducer } from 'react';
import reducer from './reducer';

/**
 * @typedef {object} HistoryExport
 * @property {string} id
 * @property {{ id: string; name: string; }} organization
 * @property {string} status
 * @property {string | null} downloadUrl
 * @property {string | null} fileName
 */

/**
 * @typedef {object} DownloaderState
 * @property {HistoryExport[]} items
 * @property {string[]} failedOrgIds
 * @property {boolean} activeExportsCheckedOnInit
 */

/** @type {React.Context<DownloaderState>} */
const DownloaderStateContext = createContext();
/** @type {React.Dispatch} */
const DownloaderDispatchContext = createContext();

const useDownloaderState = () => {
  const context = useContext(DownloaderStateContext);
  if (!context) {
    throw Error('useDownloaderState must be used inside a DownloaderProvider');
  }
  return context;
};

const useDownloaderDispatch = () => {
  const context = useContext(DownloaderDispatchContext);
  if (!context) {
    throw Error('useDownloaderDispatch must be used inside a DownloaderProvider');
  }
  return context;
};

/** @type {DownloaderState} */
const defaultInitialState = {
  items: [],
  failedOrgIds: [],
  activeExportsCheckedOnInit: false,
};

const DownloaderProvider = ({ children, initialState = defaultInitialState }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <DownloaderStateContext.Provider value={state}>
      <DownloaderDispatchContext.Provider value={dispatch}>{children}</DownloaderDispatchContext.Provider>
    </DownloaderStateContext.Provider>
  );
};

DownloaderProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialState: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
    failedOrgIds: PropTypes.arrayOf(PropTypes.string),
    activeExportsCheckedOnInit: PropTypes.bool,
  }),
};

DownloaderProvider.defaultProps = {
  initialState: defaultInitialState,
};

export {
  useDownloaderState,
  useDownloaderDispatch,
  DownloaderProvider,
  DownloaderDispatchContext,
  DownloaderStateContext,
};
