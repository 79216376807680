import services from 'api/services';
import { showLoadingOverlay, hideLoadingOverlay } from 'utils/toggleLoadingOverlay';
import { fetchPreferences } from '@control-tower/aerq-navigation-library/dist/auth/state/Preferences/actions';

export const actions = {
  GET_ORGNIZATION_MEMBERS: 'GET_ORGNIZATION_MEMBERS',
  GET_ORGANIZATION_MEMBER_DETAIL: 'GET_ORGANIZATION_MEMBER_DETAIL',
  SET_TRUSTED_SUPPLIERS: 'SET_TRUSTED_SUPPLIERS',
  SET_PARTNER_AIRLINES: 'SET_PARTNER_AIRLINES',
  REGISTER_DEVELOPER_USER: 'REGISTER_DEVELOPER_USER',
  SET_USER_ACTIVATION_DATA: 'SET_USER_ACTIVATION_DATA',
  SET_ACTIVATED_USER: 'SET_ACTIVATED_USER',
  SET_NOTIFICATION_SETTINGS: 'SET_NOTIFICATION_SETTINGS',
  SET_LANGUAGES: 'SET_LANGUAGES',
  SET_TIMEZONES: 'SET_TIMEZONES',
};

export const getOrganizationMembers = async ({ activeTab, dispatch }) => {
  try {
    showLoadingOverlay();
    const { payload } = await services.getOrganizationMembers({ status: activeTab });
    dispatch({ type: actions.GET_ORGNIZATION_MEMBERS, payload });
    return { tabName: activeTab, tableData: payload };
  } catch (error) {
    throw error;
  } finally {
    hideLoadingOverlay();
  }
};

export const getTrustedSupplierOrgList = async () => {
  const { payload } = await services.getTrustedSupplierOrgList();
  return payload;
};

export const addTrustedSupplier = async ({ values }) => {
  try {
    showLoadingOverlay();
    await services.addTrustedSupplier({ values });
  } catch (error) {
    throw error;
  } finally {
    hideLoadingOverlay();
  }
};

export const getOrganizationMemberRoles = async () => {
  /** @typedef {UM.Response<{id: string, name: string, roleSettings: {subsystemId: string, roleId: string}[]}[]>} MainRolesResponse */
  /** @typedef {UM.Response<{id: string, name: string, roles: {id: string, name: string}[]}[]>} RolesResponse */

  /** @type {[MainRolesResponse, RolesResponse]} */
  const [{ payload: mainRoles }, { payload: systems }] = await Promise.all([
    services.getMainRoles(),
    services.getOrganizationMemberRoles(),
  ]);

  return { mainRoles, systems };
};

export const getOrganizationMemberDetail = async ({ profileId }) => {
  const [{ payload: userDetails, headers }, systems] = await Promise.all([
    services.getOrganizationMemberDetail({ profileId }),
    getOrganizationMemberRoles(),
  ]);

  return {
    userDetails,
    ...systems,
    etag: headers.etag,
  };
};

export const reSendActivationEmailOwner = async ({ profileId }) => {
  try {
    showLoadingOverlay();
    await services.reSendActivationEmailOwner({ profileId });
  } finally {
    hideLoadingOverlay();
  }
};

export const updateOrganizationMemberDetails = async ({ values, etag, profileId }) => {
  try {
    showLoadingOverlay();
    const { payload } = await services.updateOrganizationMemberDetails({ values, 'If-Match': etag, profileId });
    return payload;
  } finally {
    hideLoadingOverlay();
  }
};

export const deleteOrganizationMemberPendingEmail = async ({ etag, profileId }) => {
  try {
    showLoadingOverlay();
    await services.deleteOrganizationMemberPendingEmail({ 'If-Match': etag, profileId });
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const resetPassword = async ({ profileId, etag }) => {
  try {
    showLoadingOverlay();
    await services.resetPassword({ profileId, 'If-Match': etag });
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const deleteOwnershipTransfer = async ({ etag, profileId }) => {
  try {
    showLoadingOverlay();

    const { payload } = await services.deleteOwnershipTransfer({ 'If-Match': etag, profileId });

    return payload;
  } finally {
    hideLoadingOverlay();
  }
};

export const createAirlineOrganization = async ({ values }) => {
  try {
    showLoadingOverlay();
    return services.createAirlineOrganization({ values });
  } catch (errorMessage) {
    throw errorMessage;
  } finally {
    hideLoadingOverlay();
  }
};

export const createUser = async ({ values }) => {
  try {
    showLoadingOverlay();
    return await services.createUser({ values });
  } catch (errorMessage) {
    throw errorMessage;
  } finally {
    hideLoadingOverlay();
  }
};

export const getPartnerAirlines = async ({ dispatch }) => {
  try {
    showLoadingOverlay();
    const { payload } = await services.getDeveloperPartnerAirlines();

    dispatch({ type: actions.SET_PARTNER_AIRLINES, payload });
    return payload;
  } finally {
    hideLoadingOverlay();
  }
};

export const getTrustedSuppliers = async ({ dispatch }) => {
  try {
    showLoadingOverlay();

    const result = await services.getTrustedSuppliers();

    const { payload } = result;
    dispatch({ type: actions.SET_TRUSTED_SUPPLIERS, payload });
    return payload;
  } finally {
    hideLoadingOverlay();
  }
};

export const removeTrustedSupplier = async ([id], { promiseFn, dispatch }) => {
  try {
    showLoadingOverlay();
    await services.removeTrustedSupplier({ id });

    const newSuppliers = await promiseFn({ dispatch });
    return newSuppliers;
  } finally {
    hideLoadingOverlay();
  }
};

// COTOLSY-1821
export const registerDeveloperUser = async ({ values, dispatch }) => {
  try {
    showLoadingOverlay();

    const res = await services.registerDeveloperUser({ values });

    const { payload } = res;

    dispatch({ type: actions.REGISTER_DEVELOPER_USER, payload: values });

    return payload;
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

// services.getUserActivationDataPublic = () =>
//   new Promise((resolve) =>
//     setTimeout(resolve, 2000, {
//       payload: {
//         profile: {
//           name: 'My name comes here',
//           email: 'my@email.com',
//           isExistingUser: true,
//         },
//         organization: {
//           name: 'Valid organisation name',
//           type: 'airline',
//           status: 'active',
//         },
//       },
//     })
//   );

// services.activateUserPublic = () =>
//   new Promise((resolve) =>
//     setTimeout(resolve, 2000, {
//       payload: {},
//     })
//   );

export const getUserActivationData = async ({ isPublic, token, dispatch }) => {
  try {
    showLoadingOverlay();

    if (!token) {
      return {};
    }

    const promiseFn = isPublic ? services.getUserActivationDataPublic : services.getUserActivationData;

    const { payload } = await promiseFn({ token });

    if (dispatch) {
      dispatch({ type: actions.SET_USER_ACTIVATION_DATA, payload });
    }

    return payload;
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const activateUser = async ({ dispatch, isPublic, token, values }) => {
  try {
    showLoadingOverlay();

    const { email, ...rest } = values;

    const promiseFn = isPublic ? services.activateUserPublic : services.activateUser;

    const { payload } = await promiseFn({ token, values: rest });

    if (dispatch) {
      dispatch({ type: actions.SET_ACTIVATED_USER, payload });
    }
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

/**
 * @typedef {object} NotificationSetting
 * @property {string} categoryId
 * @property {boolean} enabled
 */

/**
 * @typedef {NotificationSetting[]} NotificationSettingsResponse
 */

export const getNotificationSettings = async ({ dispatch }) => {
  try {
    showLoadingOverlay();

    /** @type {import('common/types').Response<NotificationSettingsResponse>} */
    const res = await services.fetchNotificationSettings();

    const { payload } = res;

    dispatch({ payload, type: actions.SET_NOTIFICATION_SETTINGS });

    return payload;
  } finally {
    hideLoadingOverlay();
  }
};

export const saveNotificationSettings = async ({ values, dispatch }) => {
  try {
    showLoadingOverlay();

    await services.saveNotificationSettings({ values });
    await getNotificationSettings({ dispatch });
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const saveLanguageAndRegionSettings = async ({ values, preferencesDispatch }) => {
  try {
    showLoadingOverlay();

    await services.saveLanguageAndRegionSettings({ values });
    await fetchPreferences({ preferencesDispatch });
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const getLanguagesAndRegions = async ({ dispatch }) => {
  try {
    showLoadingOverlay();

    const [languageResult, timeZoneResult] = await Promise.all([services.getLanguages(), services.getTimezones()]);

    const { payload: languagePayload } = languageResult;
    const { payload: timeZonePayload } = timeZoneResult;
    dispatch({ type: actions.SET_LANGUAGES, payload: languagePayload });
    dispatch({ type: actions.SET_TIMEZONES, payload: timeZonePayload });

    return { languageResult, timeZoneResult };
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};
