const OrganisationsOverview = (theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  addAirlineBtn: {
    flexShrink: 0,
    alignSelf: 'flex-start',
  },
  table: {
    marginTop: theme.spacing(6),
  },
  capitalize: { textTransform: 'capitalize' },
  tableDataRow: {
    '& *': {
      color: theme.palette.grey[800],
    },
  },
  emptyState: {},
  button: {
    flexShrink: 0,
    marginLeft: theme.spacing(1),
  },
  title: {
    flexShrink: 1,
  },
  hideContent: {
    visibility: 'hidden',
  },
  subMenu: {
    marginBottom: theme.spacing(9),
  },
});

export { OrganisationsOverview as default };
