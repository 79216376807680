const SaveFormButtonMolecule = (theme) => ({
  saveButtonPlaceholder: {
    ...theme.fontWeights.Normal,
    fontSize: theme.fontSizes.Small,
    lineHeight: '12px',
    color: theme.palette.grey[600],
    whiteSpace: 'nowrap',
    '& svg': {
      width: 16,
      height: 16,
      verticalAlign: 'text-bottom',
      paddingRight: 8,
      boxSizing: 'content-box',
      position: 'relative',
      top: -2,
    },
  },
});

export default SaveFormButtonMolecule;
