const PartnerAirlinesCardMolecule = (theme) => ({
  root: {
    width: 730,
    padding: [[theme.spacing(3), theme.spacing(3), theme.spacing(4)]],
    border: [[1, 'solid', theme.palette.grey[400]]],
    borderRadius: theme.spacing(0.5),
  },
  logo: {
    marginRight: theme.spacing(1),
  },
  rowCell: {
    marginTop: 22,
    overflowWrap: 'break-word',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
});

export { PartnerAirlinesCardMolecule as default };
