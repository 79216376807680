import { useEffect } from 'react';
import { useMessagesDispatch } from 'state/Messages/context';
import { showErrorDialog } from 'state/Messages/actions';

const NoMatch = () => {
  const dispatch = useMessagesDispatch();

  useEffect(() => {
    dispatch(showErrorDialog('404'));
  }, [dispatch]);

  return null;
};

export { NoMatch as default };
