const UsersContentMolecule = (theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  hideContent: {
    visibility: 'hidden',
  },
  notClickableRow: {
    cursor: 'default',
  },
  youLabel: {
    fontWeight: 600,
  },
  modalInnerTitle: {
    marginBottom: theme.spacing(2),
  },
});

export { UsersContentMolecule as default };
