const DownloadHandlerMolecule = (theme) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    zIndex: 99,
    paddingBottom: theme.spacing(1),
  },
});

export default DownloadHandlerMolecule;
