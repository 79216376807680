import { Header } from '@control-tower/aerq-navigation-library';
import { useMainState } from '@control-tower/aerq-navigation-library/dist/auth/state/Main/context';
import { ENV_LABEL } from 'common/constants';
import { ReactComponent as BcHeaderLogo } from 'assets/svg/bc-headerlogo.svg';
import { Link, useLocation } from 'react-router-dom';
import useNavigate from 'common/hooks/useNavigate';

const HeaderController = (props) => {
  const { theme: themeName } = useMainState();

  const navigateTo = useNavigate();
  const location = useLocation();

  if (themeName === 'bc') {
    return (
      <Header
        Link={Link}
        navigateTo={navigateTo}
        location={location}
        {...props}
        logo={<BcHeaderLogo />}
        envLabel={ENV_LABEL}
      />
    );
  }

  return <Header fixed Link={Link} navigateTo={navigateTo} location={location} {...props} envLabel={ENV_LABEL} />;
};

export default HeaderController;
