export const UserEditorMolecule = (theme) => ({
  root: {
    '& .modal-paper': {
      maxHeight: '96vh',
    },
  },
  formPlaceholder: {
    height: 521,
  },
  form: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    '& .user-editor-form-text-link': {
      marginBottom: theme.spacing(4),
      '&:last-child': {
        marginBottom: 0,
      },
    },
    '& .user-editor-form-text-link.other-system': {
      marginBottom: theme.spacing(3),
    },
    '& .other-system-info': {
      marginBottom: theme.spacing(4),
      '&:last-child': {
        marginBottom: 0,
      },
    },
    '& $field': {
      width: 424,
      marginTop: 0,
    },
    '& $field + $field': {
      marginTop: theme.spacing(5),
    },
  },
  field: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(6),
    '& + $sectionTitle': {
      marginTop: theme.spacing(5),
    },
    '& + $callout': {
      marginTop: theme.spacing(1),
    },
  },
  titleContainer: {
    height: 78,
    maxWidth: 'calc(100% - 200px)',
  },
  title: {
    marginBottom: 0,
    '& h1': {
      maxWidth: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  sectionTitle: {
    margin: [[theme.spacing(3), 0, theme.spacing(5)]],
  },
  rolesTitle: {
    margin: [[theme.spacing(5), 0, theme.spacing(4)]],
  },
  actionTitle: {
    margin: [[theme.spacing(4), 0, theme.spacing(3)]],
  },
  buttonWrapper: {
    height: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
  },

  progressBar: {
    background: theme.palette.grey['200'],
    '& .MuiLinearProgress-bar': {
      background: `linear-gradient(90deg, ${theme.palette.primary.light} -5%, ${theme.palette.primary.dark} 100%)`,
    },
  },
  modalInnerTitle: {
    marginBottom: theme.spacing(2),
  },
  callout: {
    '& strong': {
      ...theme.fontWeights.Bold,
    },
  },
  calloutButton: {
    marginTop: 20,
  },
  resetPasswordButton: {
    maxWidth: 200,
  },
  mainRoleHelperText: {
    paddingTop: theme.spacing(1),
    color: theme.palette.grey[600],
    fontSize: theme.fontSizes.Small,
    ...theme.fontWeights.Normal,
    '& span': {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: theme.palette.common.black,
      ...theme.fontWeights.Medium,
    },
  },
  backIcon: {
    '& .startColor': {
      'stop-color': theme.palette.primary.dark,
    },
    '& .endColor': {
      'stop-color': theme.palette.primary.light,
    },
  },
  btnEmailResend: {
    marginTop: theme.spacing(3),
    maxWidth: 300,
  },
});

export { UserEditorMolecule as default };
