const ErrorDialogMolecule = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    margin: `${theme.spacing(4)} 10%`,
  },
  content: {
    alignSelf: 'center',
    textAlign: 'center',
    margin: '7% 0',
  },
  title: {
    margin: '8px 0',
  },
  text: {
    margin: '16px 0',
  },
});

export default ErrorDialogMolecule;
