import PropTypes from 'prop-types';
import { createContext, useContext, useReducer } from 'react';
import { reducer, initialState } from './reducer';

/**
 * @typedef {object} UserStateContextValue
 * @property {import('common/types').Maybe<import('./actions').NotificationSettingsResponse>} notificationSettings
 */

/** @type {React.Context<UserStateContextValue>} */
const UserStateContext = createContext();

const UserDispatchContext = createContext();

const useUserState = () => {
  const context = useContext(UserStateContext);
  if (!context) {
    throw Error('useUserState must be used inside a UserProvider');
  }
  return context;
};

const useUserDispatch = () => {
  const context = useContext(UserDispatchContext);
  if (!context) {
    throw Error('useUserDispatch must be used inside a UserProvider');
  }
  return context;
};

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserProvider, useUserDispatch, useUserState, UserStateContext, UserDispatchContext };
