/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
import { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Header } from 'view/components/header';
import DesignSystem from 'theme/design_system/designSystem';
import { withTranslation, Trans } from 'react-i18next';
import {
  CustomButton as Button,
  CenteredContentTemplate,
  Label,
  PageTemplate,
  PageContent,
  SubHeader,
  PageFooter,
  ChevronRight,
  TextLink,
} from '@control-tower/aerq-ui-library';
import { uniqueId } from 'lodash';
import { KeycloakContext } from '@control-tower/aerq-navigation-library';
import Footer from 'view/components/Footer/Footer';
import { useMainState } from '@control-tower/aerq-navigation-library/dist/auth/state/Main/context';
import { useNavigate } from 'react-router-dom';

const useStyles = DesignSystem.organisms.UserRegistration();

const UserRegistration = (props) => {
  const { t } = props;

  const { organizationInfo } = useMainState();

  const classes = useStyles();

  const keycloak = useContext(KeycloakContext);

  const goToLogin = useCallback(() => {
    if (organizationInfo !== null) {
      keycloak.logout({ redirectUri: window.location.origin });

      return;
    }

    window.location.replace('/');
  }, [organizationInfo, keycloak]);

  const navigate = useNavigate();

  return (
    <PageTemplate>
      <Header showMenusAndOrg={false} showProfileMenu={false} />
      <PageContent className={classes.pageContent} backgroundType="gradient-large" backgroundHeight={955}>
        <CenteredContentTemplate gridTemplateNumbers={[2, 8, 2]}>
          <SubHeader lineHeight="88px" title={t('user.userRegistration.mainTitle')} className={classes.subHeader} />

          <Label
            variant="h2"
            type="primary"
            color="black"
            className={classes.sectionTitle}
            title={t('user.userRegistration.forAirlinesTitle')}
          />

          <Label
            variant="h3"
            type="grey"
            color="700"
            className={classes.infoText}
            title={
              <Trans
                i18nKey="user.userRegistration.forAirlinesText"
                // eslint-disable-next-line jsx-a11y/control-has-associated-label
                components={[<a target="_blank" href={t('general.aerqContactLink')} />]}
              />
            }
          />

          <Label
            variant="h2"
            type="primary"
            color="black"
            className={classes.sectionTitle}
            title={t('user.userRegistration.forDevelopersTitle')}
          />

          <Label
            variant="h3"
            type="grey"
            color="700"
            className={classes.infoText}
            title={
              <Trans
                i18nKey="user.userRegistration.forDevelopersText"
                components={[<span key={uniqueId()} className={classes.translationBr} />]}
              />
            }
          />
          <Button
            className={classes.btnUserRegistration}
            onClick={() => {
              navigate('/sign-up-developer');
            }}
            dataTestId="user-registration-btn"
          >
            {t('user.userRegistration.btnCreateDevOrg')}
          </Button>

          <div className={classes.loginContainer}>
            <Label
              variant="body1"
              type="grey"
              color="700"
              className={classes.loginInfo}
              title={t('user.userRegistration.loginInfo')}
            />
            <TextLink
              onClick={goToLogin}
              className={classes.btnLogin}
              color="primary"
              underline="hover"
              endIcon={<ChevronRight />}
            >
              {t('user.userRegistration.btnLogin')}
            </TextLink>
          </div>
        </CenteredContentTemplate>
      </PageContent>
      <PageFooter>
        <Footer />
      </PageFooter>
    </PageTemplate>
  );
};

UserRegistration.propTypes = {
  t: PropTypes.func.isRequired,
};

UserRegistration.defaultProps = {};

export default withTranslation()(UserRegistration);
