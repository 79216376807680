import { Header } from 'view/components/header';
import {
  ContentContainerTemplate as ContentContainer,
  PageTemplate,
  PageContent,
  SubHeader,
} from '@control-tower/aerq-ui-library';
import { useTranslation } from 'react-i18next';
import DesignSystem from 'theme/design_system/designSystem';

const useStyles = DesignSystem.molecules.EmailChangeErrorPage();

const EmailChangeErrorPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <PageTemplate>
      <Header showMenusAndOrg={false} showProfileMenu={false} />
      <PageContent>
        <ContentContainer>
          <SubHeader
            className={classes.root}
            title={t(`user.emailVerification.error.title`)}
            text={t(`user.emailVerification.error.subtitle`)}
          />
        </ContentContainer>
      </PageContent>
    </PageTemplate>
  );
};

export { EmailChangeErrorPage as default };
