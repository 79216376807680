const MyOrganizationEditorOrganism = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
  },
  content: {
    padding: '0px 0px',
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  field: {},
  uploader: {
    '& .label-text': {
      marginTop: 0,
      marginLeft: theme.spacing(0.5),
    },
    '& img': {
      width: 'auto',
    },
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 424,
    flexShrink: 1,
    overflow: 'visible',
    '& $field': {
      width: 424,
      marginTop: 0,
    },
    '& $field ~ $field': {
      marginTop: theme.spacing(5),
    },
  },
});

export { MyOrganizationEditorOrganism as default };
