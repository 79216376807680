import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import packageJson from '../package.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: true,
    },
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`,
      queryStringParams: { v: packageJson.version },
    },
  });

export default i18n;
