import { Footer as NavLibFooter } from '@control-tower/aerq-navigation-library';
import {
  useAuthConfState,
  useAuthConfDispatch,
} from '@control-tower/aerq-navigation-library/dist/auth/state/Auth/authContext';
import { setConfiguration } from '@control-tower/aerq-navigation-library/dist/auth/state/Auth/actions';

import { useEffect } from 'react';
import { USER_MANAGER_BLOB_URL_PREFIX } from 'common/constants';

const Footer = () => {
  const { configuration } = useAuthConfState();
  const dispatch = useAuthConfDispatch();

  useEffect(() => {
    if (!configuration.find((config) => config.name === 'blob_container.public_url')) {
      dispatch(
        setConfiguration([...configuration, { name: 'blob_container.public_url', value: USER_MANAGER_BLOB_URL_PREFIX }])
      );
    }
  }, [configuration, dispatch]);

  return <NavLibFooter />;
};

export default Footer;
