const ExportForm = (theme) => ({
  title: {
    marginTop: theme.spacing(6),
  },
  text: {
    marginTop: theme.spacing(1),
    color: theme.palette.grey[800],
  },
  period: {
    maxWidth: 434,
    marginTop: theme.spacing(5),
  },
  dateContainer: {
    maxWidth: 434,
    display: 'flex',
    marginTop: theme.spacing(3),
  },
  datePicker: {
    marginTop: theme.spacing(2.5),
    '&:nth-child(2)': {
      marginLeft: theme.spacing(1),
    },
  },
  progressTitle: {},
  progress: {
    marginTop: theme.spacing(2),
    '& $progressTitle': {
      marginBottom: theme.spacing(4),
      '& span': {
        fontSize: theme.fontSizes.Small,
      },
    },
    '& circle': {
      stroke: 'url(#svg_primary_linear_gradient_fill) !important',
    },
  },
  modalRoot: {
    '& .modal-container': {
      marginTop: 0,
    },
  },
  btnDownload: {
    marginRight: theme.spacing(3),
  },
  createdTitle: {
    marginTop: theme.spacing(2),
    fontSize: theme.fontSizes.Small,
    display: 'block',
    marginBottom: theme.spacing(9),
  },
  actions: {
    marginTop: theme.spacing(6),
  },
});

export default ExportForm;
