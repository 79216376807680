const UserActivationJoinOrgSuccessOrganism = (theme) => ({
  pageContent: {
    paddingTop: 144,
    paddingBottom: 144,
  },
  subHeader: {
    marginBottom: 16,
  },
  infoText: {
    fontSize: 18,
    lineHeight: theme.spacing(3.5),
  },
  btnGoToHome: {
    marginTop: theme.spacing(4),
  },
});

export default UserActivationJoinOrgSuccessOrganism;
