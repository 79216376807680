import { SideMenuItem, SideMenuSectionTitle } from '../molecules';

const SideMenuOrganism = (theme) => ({
  ...SideMenuItem(theme),
  ...SideMenuSectionTitle(theme),
  menu: {
    overflow: 'visible',
    '& .MuiTabs-flexContainer': {
      flexDirection: 'column',
    },
    '& .MuiTabs-scroller': {
      overflow: 'visible !important',
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
});

export default SideMenuOrganism;
