const UserRegistrationOrganism = (theme) => ({
  pageContent: {
    paddingTop: 144,
    paddingBottom: 144,
  },
  callout: {
    '& strong': {
      ...theme.fontWeights.Bold,
    },
  },
  translationBr: {
    display: 'block',
    lineHeight: theme.spacing(3.5),
    '&::before': {
      content: "'\\00a0 '",
    },
  },
  infoText: {
    fontSize: 20,
    lineHeight: theme.spacing(3.5),
    marginTop: theme.spacing(2),
    '& a': {
      color: theme.palette.grey[900],
      ...theme.fontWeights.Medium,
    },
  },
  sectionTitle: {
    ...theme.fontWeights.Medium,
    marginTop: theme.spacing(6),
  },
  loginContainer: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    marginTop: theme.spacing(10),
    paddingTop: theme.spacing(4),
  },
  btnLogin: {
    marginTop: theme.spacing(2),
  },
  btnUserRegistration: {
    marginTop: theme.spacing(6),
  },
});

export default UserRegistrationOrganism;
