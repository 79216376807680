const InfromationMolecule = (theme) => ({
  root: {
    maxWidth: 424,
    '& .rowCellInfoContent img': {
      width: 'auto',
    },
    marginTop: theme.spacing(8),
    '& .rowCell': {
      marginTop: 0,
    },
    '& .rowCell ~ .rowCell': {
      marginTop: theme.spacing(7),
    },
  },
});

export { InfromationMolecule as default };
