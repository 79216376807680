const TrustedSuppliersMolecule = (theme) => ({
  root: {
    marginTop: theme.spacing(6.25),
  },
  hide: {
    visibility: 'hidden',
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  description: {
    marginTop: theme.spacing(3),
  },
  content: {
    maxWidth: 730,
  },
  card: {
    marginTop: theme.spacing(4),
  },
});

export { TrustedSuppliersMolecule as default };
