const AddUserModalMolecule = (theme) => ({
  form: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'hidden',
    '& $field': {
      marginTop: theme.spacing(4),
    },
    '& $field ~ $field': {
      marginTop: theme.spacing(5),
    },
  },
  field: {
    maxWidth: 424,
  },
  buttonWrapper: {
    flexShrink: 0,
    border: [[1, 'solid', theme.palette.grey[300]]],
    padding: [[16, '11.5%', 32]],
    display: 'flex',
    justifyContent: 'flex-end',
  },
  cancelBtn: {
    marginRight: theme.spacing(3),
  },
  hide: {
    visibility: 'hidden',
    opacity: 0,
  },
  main: {
    margin: [[theme.spacing(6), '11.5%', theme.spacing(5)]],
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '& h4': {
      marginTop: theme.spacing(3),
    },
    '& h2': {
      marginTop: theme.spacing(6),
    },
  },
  scrollableContentWrapper: {
    display: 'flex',
    overflow: 'hidden',
  },
  scrollableContent: {
    flexGrow: 1,
    width: '100%',
    padding: [[theme.spacing(1), 0]],
  },
  root: {
    '& .modal-header': {
      display: 'none',
    },
  },
  mainRoleHelperText: {
    paddingTop: theme.spacing(1),
    color: theme.palette.grey[600],
    fontSize: theme.fontSizes.Small,
    ...theme.fontWeights.Normal,
    '& span': {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: theme.palette.common.black,
      ...theme.fontWeights.Medium,
    },
  },
  otherSystemInfo: {
    marginTop: theme.spacing(3),
  },
});

export { AddUserModalMolecule as default };
