export const LogLevel = {
  DEBUG: 'DEBUG',
  ERROR: 'ERROR',
  INFO: 'INFO',
  WARNING: 'WARNING',
};

export const MessageLevel = {
  ERROR: 'ERROR',
  INFO: 'INFO',
  WARNING: 'WARNING',
};

export const AirlineVersionStatus = {
  READY_FOR_DEPLOYMENT: 'ready_for_deployment',
  ROLLED_OUT: 'rolled_out',
};

export const OrganizationType = {
  INTERNAL: 'INTERNAL',
  DEVELOPER: 'DEVELOPER',
  AIRLINE: 'AIRLINE',
};

export const OrganizationStatus = {
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  ARCHIVED: 'ARCHIVED',
};

export const UserRole = {
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  EXPERIENCE_MANAGER: 'EXPERIENCE_MANAGER',
  VIEWER: 'VIEWER',
  APP_EDITOR: 'APP_EDITOR',
  SUPERADMIN: 'SUPERADMIN',
  REVIEWER: 'REVIEWER',
  AGREEMENT_MANAGER: 'AGREEMENT_MANAGER',
};

export const UserStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING',
  ARCHIVED: 'ARCHIVED',
  ORGANIZATION_DEACTIVATED: 'ORGANIZATION_DEACTIVATED',
};

/** @enum {'null'|'CANCELED'|'IN_PROGRESS','CREATED','DOWNLOADED'} */
export const ExportStatus = {
  null: 'null',
  CANCELED: 'CANCELED',
  IN_PROGRESS: 'IN_PROGRESS',
  CREATED: 'CREATED',
  DOWNLOADED: 'DOWNLOADED',
  FAILED: 'FAILED',
  FAILED_HANDLED: 'FAILED_HANDLED',
};
