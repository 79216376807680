const MainRoleMultiPageModalMolecule = (theme) => ({
  subTitle: {
    ...theme.fontWeights.Semibold,
    fontSize: theme.fontSizes.Large,
  },
  description: {
    fontSize: theme.fontSizes.Normal,
  },
  mainRolesListTitle: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(4),
    ...theme.fontWeights.Semibold,
    fontSize: theme.fontSizes.Large,
  },
  wideLink: {
    marginBottom: theme.spacing(2),
  },
  backNav: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: '36.8px',
  },
  backNavLabel: {
    marginLeft: theme.spacing(2),
    fontSize: theme.fontSizes.Normal,
    ...theme.fontWeights.Medium,
  },
  selectedRoleName: {
    marginBottom: theme.spacing(4),
  },
  individualTitle: {
    ...theme.fontWeights.Semibold,
    fontSize: theme.fontSizes.Large,
    marginTop: theme.spacing(4.25),
  },
  systemName: {
    marginTop: theme.spacing(4),
    fontSize: theme.fontSizes.Large,
    marginBottom: theme.spacing(1.5),
  },
  individualRoles: {
    marginBottom: 30,
  },
});

export { MainRoleMultiPageModalMolecule as default };
