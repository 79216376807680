import PropTypes from 'prop-types';
import { createContext, useContext, useReducer } from 'react';
import { initialState, reducer } from './reducer';

const AccountStateContext = createContext();
const AccountDispatchContext = createContext();

const useAccountsStateContext = () => {
  const context = useContext(AccountStateContext);
  if (!context) {
    throw Error('useAccountsStateContext must be inside AccountsProvider');
  }
  return context;
};

const useAccountsDispatchContext = () => {
  const context = useContext(AccountDispatchContext);
  if (!context) {
    throw Error('useAccountsDispatchContext must be inside AccountsProvider');
  }
  return context;
};

const AccountsProvider = ({ children, initialState: initialStateProp }) => {
  const [state, dispatch] = useReducer(reducer, { ...initialStateProp, ...initialState });
  return (
    <AccountStateContext.Provider value={state}>
      <AccountDispatchContext.Provider value={dispatch}>{children}</AccountDispatchContext.Provider>
    </AccountStateContext.Provider>
  );
};

AccountsProvider.propTypes = {
  children: PropTypes.node,
  initialState: PropTypes.shape({}),
};
AccountsProvider.defaultProps = {
  children: null,
  initialState: null,
};

export {
  AccountsProvider,
  useAccountsStateContext,
  useAccountsDispatchContext,
  AccountStateContext,
  AccountDispatchContext,
};
