const SubSystemInfoModalMolecule = (theme) => ({
  root: {
    '& .modal-paper': {
      '& .modal-container': {
        '& .modal-title': {
          marginBottom: theme.spacing(4),
        },
        '& .main-info-container': {
          marginBottom: theme.spacing(5),

          '& .main-title': {
            marginBottom: theme.spacing(2),
          },

          '& .description': {
            fontSize: theme.fontSizes.Normal,
            margin: 0,
          },
        },
        '& .role-info-container': {
          '& .main-title': {
            marginBottom: theme.spacing(2),
          },
          '& .role-description-container': {
            marginBottom: theme.spacing(4),
            '&:last-child': {
              marginBottom: 0,
            },
            '& .description': {
              fontSize: theme.fontSizes.Normal,
              marginTop: '12px',
              '&:last-child': {
                marginBottom: 0,
              },
            },
          },
        },
      },
    },
  },
});

export { SubSystemInfoModalMolecule as default };
