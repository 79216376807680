import Dialog from '@mui/material/Dialog';
import Proptypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useMessagesState, useMessagesDispatch } from 'state/Messages/context';
import { hideErrorDialog } from 'state/Messages/actions';
import BlockingErrorView from './BlockingErrorView';

const ErrorDialog = (props) => {
  const { children } = props;
  const { dialog } = useMessagesState();
  const dispatch = useMessagesDispatch();
  const { open } = dialog;

  const previousUrl = useRef(null);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (window.location.href !== previousUrl.current) {
        if (previousUrl.current !== null && open) {
          dispatch(hideErrorDialog());
        }

        previousUrl.current = window.location.href;
      }
    });
    observer.observe(document.body, { attributes: false, childList: true, subtree: false });
    return () => {
      observer.disconnect();
    };
  }, [dispatch, open]);

  return (
    <>
      {!!open && (
        <Dialog fullScreen open={open}>
          <BlockingErrorView />
        </Dialog>
      )}
      {children}
    </>
  );
};

ErrorDialog.propTypes = {
  children: Proptypes.node.isRequired,
};

export default ErrorDialog;
