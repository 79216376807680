const DownloadWidgetMolecule = (theme) => ({
  root: {
    position: 'relative',
    marginTop: theme.spacing(0.5),
    width: 511,
    background: theme.palette.common.white,
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.08)',
    display: 'flex',
    padding: [[4, 16]],
    alignItems: 'center',
    justifyContent: 'space-between',
    right: theme.spacing(1),
    height: 76,
    overflow: 'hidden',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    fontSize: theme.fontSizes.Normal,
    color: theme.palette.common.black,
    lineHeight: '28px',
    display: 'block',
  },
  appName: {
    fontSize: theme.fontSizes.XXSmall,
    color: theme.palette.grey[500],
    lineHeight: '14px',
    display: 'block',
  },
  info: {
    marginLeft: theme.spacing(3),
  },
  btnCancel: ({ cancelDisabled }) => ({
    cursor: 'pointer',
    pointerEvents: cancelDisabled ? 'none' : 'auto',
    color: theme.palette.grey[600],
    fontSize: theme.fontSizes.Normal,
    lineHeight: '19px',
    '&:hover': {
      color: theme.palette.common.black,
    },
  }),
  icons: {
    '& circle': {
      stroke: 'url(#svg_primary_linear_gradient_fill) !important',
    },
  },
  cancel: {
    position: 'relative',
    right: '4px',
    '& path': {
      stroke: theme.palette.error.main,
    },
  },
  downloadActions: {
    display: 'flex',
    alignItems: 'center',
  },
  cancelIcon: {
    marginLeft: 12,
    cursor: 'pointer',
    '& path': {
      stroke: theme.palette.grey[600],
      strokeWidth: '1.5',
    },
    '&:hover path': {
      stroke: theme.palette.common.black,
    },
  },
});

export default DownloadWidgetMolecule;
