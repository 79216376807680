import axios from 'axios';
import matchesPath from 'common/matchesPath';
import { addMessage, showErrorDialog } from 'state/Messages/actions';

/**
 *
 * @param {object} params
 * @param {{ code: number; level?: string; text?: string; reference?: string; }[]} params.messages
 * @param {(action: { type: string; payload: unknown }) => void} params.dispatch
 * @param {import('i18next').i18n} params.i18n
 */
export const handleMessages = ({ messages = [], dispatch, i18n }) => {
  const genericMessages = messages.filter((message) => !('reference' in message));

  genericMessages.forEach(({ text, level, code }) => {
    const message = i18n.exists(`messages.${code}`) ? i18n.t(`messages.${code}`) : text;

    dispatch(addMessage({ variant: level.toLowerCase(), message }));
  });
};

const statusCodesToHandle = [422];

/**
 * @param {any} error
 * @param {{ path: string; method?: string; }[]} routes
 *
 * @returns {boolean}
 */
export const disableGlobalErrorHandling = (error, routes, errorCodes = []) => {
  if (!error || !error.response) {
    return false;
  }

  if (errorCodes.includes(parseInt(error.response.status, 10))) {
    return true;
  }

  const { url, method } = error.response.config || {};

  const index = routes.findIndex((route) => {
    if (!route.method) {
      return matchesPath(route.path, url);
    }

    return matchesPath(route.path, url) && route.method.toLowerCase() === method.toLowerCase();
  });

  if (index === -1) {
    return false;
  }

  return true;
};

const setupMessageHook = (messageDispatch, translation) => {
  const { i18n } = translation;

  return axios.interceptors.response.use(
    (response) => {
      const { config, data, headers } = response;

      if (config?.responseType === 'blob') {
        return { data, headers };
      }

      handleMessages({ responseData: data, dispatcher: messageDispatch, i18n });
      return { ...data, headers };
    },
    (error) => {
      if (!error.response) {
        return Promise.reject(error);
      }

      // handle the error in a non-generic way in place
      if (
        disableGlobalErrorHandling(
          error,
          [{ method: 'patch', path: '/accounts/organizations/:organizationId' }],
          statusCodesToHandle
        )
      ) {
        return Promise.reject(error.response.data);
      }

      const { data = {}, status } = error.response;

      const { messages = [] } = data;

      if (messages.length) {
        handleMessages({ responseData: data, dispatcher: messageDispatch, i18n });
      }

      // if we cant handle the error show a blocking view
      messageDispatch(showErrorDialog(status, messages));

      return Promise.reject(error);
    }
  );
};

export default setupMessageHook;
