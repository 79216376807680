import * as actions from './actions';

const reducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case actions.SET_BACKEND_VERSION: {
      return { ...state, ...payload };
    }
    default:
      return state;
  }
};
export default reducer;
