import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useMessagesState, useMessagesDispatch } from 'state/Messages/context';
import { removeMessage } from 'state/Messages/actions';
import { Snackbar } from '@control-tower/aerq-ui-library';

const Messages = () => {
  const dispatch = useMessagesDispatch();
  const { messages } = useMessagesState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    messages.forEach(({ key, message, options = {} }) => {
      // Display snackbar using notistack
      enqueueSnackbar(message, {
        ...options,
        content: (contentKey) => {
          const { variant } = options || { variant: undefined };
          return (
            <Snackbar
              onClick={() => {
                closeSnackbar(contentKey);
              }}
              message={message}
              variant={variant}
            />
          );
        },
      });

      // Remove message from state
      dispatch(removeMessage({ key }));
    });
  }, [closeSnackbar, dispatch, enqueueSnackbar, messages]);

  return null;
};

export default Messages;
