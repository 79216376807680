import { useMemo, useCallback, useContext } from 'react';
import { Header } from 'view/components/header';
import DesignSystem from 'theme/design_system/designSystem';
import { Trans, useTranslation } from 'react-i18next';
import { useAsync } from 'react-async';
import { verifyEmailChange } from 'state/Profile/actions';
import {
  CenteredContentTemplate,
  CustomButton,
  Label,
  PageContent,
  PageTemplate,
  SubHeader,
} from '@control-tower/aerq-ui-library';
import { KeycloakContext } from '@control-tower/aerq-navigation-library';
import { useMainState } from '@control-tower/aerq-navigation-library/dist/auth/state/Main/context';
import qs from 'query-string';

const useStyles = DesignSystem.organisms.EmailChangeSuccess();

const EmailChangeSuccess = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const keycloak = useContext(KeycloakContext);

  const token = useMemo(() => {
    const parsedQuery = qs.parse(window.location.search);
    if (!parsedQuery.token) {
      return null;
    }

    return parsedQuery.token;
  }, []);

  const { organizationInfo } = useMainState();

  const goToHome = useCallback(() => {
    if (organizationInfo !== null) {
      keycloak.logout({ redirectUri: window.location.origin });

      return;
    }

    window.location.replace('/');
  }, [organizationInfo, keycloak]);

  const { isLoading, data } = useAsync({
    promiseFn: verifyEmailChange,
    token,
  });

  if (isLoading) {
    return null;
  }

  return (
    <PageTemplate>
      <Header showMenusAndOrg={false} showProfileMenu={false} />
      <PageContent className={classes.pageContent} backgroundType="gradient-large" backgroundHeight={955}>
        <CenteredContentTemplate gridTemplateNumbers={[2, 8, 2]}>
          <SubHeader lineHeight="88px" title={t('user.emailVerification.successTitle')} className={classes.subHeader} />
          <Label variant="h3" type="grey" color="700" className={classes.infoText}>
            <Trans
              i18nKey="user.emailVerification.successInfo"
              values={{ email: data.newEmail }}
              components={[<strong />]}
            />
          </Label>
          <CustomButton onClick={goToHome} className={classes.btnGoToHome} data-testid="btn-go-to-home">
            {t('user.buttons.goToHome')}
          </CustomButton>
        </CenteredContentTemplate>
      </PageContent>
    </PageTemplate>
  );
};

EmailChangeSuccess.propTypes = {};

export default EmailChangeSuccess;
