/* eslint-disable import/no-cycle */
import services from 'api/services';
import { showLoadingOverlay, hideLoadingOverlay } from 'utils/toggleLoadingOverlay';
import {
  setProfile,
  fetchProfile,
  setOrganization,
} from '@control-tower/aerq-navigation-library/dist/auth/state/Profile/actions';

export const SET_USER_ACTIVATION_DATA = 'SET_USER_ACTIVATION_DATA';
export const REGISTER_DEVELOPER_USER = 'REGISTER_DEVELOPER_USER';

export const updateProfile = async ({ values, etag, dispatch }) => {
  try {
    showLoadingOverlay();

    const res = await services.updateProfile({
      values,
      'If-Match': etag,
    });

    const { payload: profile, headers } = res;

    dispatch(setProfile({ ...profile, etag: headers.etag }));
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const deleteProfilePendingEmail = async ({ etag, dispatch }) => {
  try {
    showLoadingOverlay();

    await services.deleteProfilePendingEmail({
      'If-Match': etag,
    });
    await fetchProfile({ profileDispatch: dispatch });
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const updateMyOrganization = async ({ values, etag, dispatch }) => {
  try {
    showLoadingOverlay();

    const { email, phone, website, logoFileRef } = values;

    const result = await services.updateMyOrganization({
      values: { email, phone, website, logoFileRef },
      'If-Match': etag,
    });

    const { payload, headers } = result;

    dispatch(setOrganization({ ...payload, etag: headers.etag }));

    return payload;
  } catch (e) {
    throw e;
  } finally {
    hideLoadingOverlay();
  }
};

/**
 * @typedef {UM.Profile & {organization: UM.Organization }} ConfirmPendingOwnershipPayload
 */

/** @type {import('react-async').PromiseFn<ConfirmPendingOwnershipPayload>} */
export const confirmPendingOwnership = async ({ dispatch, token }) => {
  try {
    showLoadingOverlay();

    /** @type {UM.Response<ConfirmPendingOwnershipPayload>} */
    const { payload } = await services.confirmPendingOwnership({ token });

    dispatch(setProfile(payload));

    return payload;
  } finally {
    hideLoadingOverlay();
  }
};

export const uploadOrganizationLogo = async ({ onUploadProgress, file }) => {
  try {
    const { payload } = await services.uploadOrganizationLogo({ onUploadProgress, file });
    return payload;
  } catch (error) {
    const { messages } = error;
    return messages;
  }
};

/**
 * @typedef {object} VerifyEmailChangePayload
 * @property {string} oldEmail
 * @property {string} newEmail
 */

/** @type {import('react-async').PromiseFn<VerifyEmailChangePayload>} */
export const verifyEmailChange = async ({ token }) => {
  try {
    showLoadingOverlay();

    /** @type {UM.Response<VerifyEmailChangePayload>}  */
    const { payload } = await services.verifyEmailChange({ token });

    return payload;
  } finally {
    hideLoadingOverlay();
  }
};

export const changePassword = async ({ values }) => {
  try {
    showLoadingOverlay();

    await services.changePassword({
      values,
    });
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const getProfileWithLoadingScreen = async ({ dispatch }) => {
  try {
    showLoadingOverlay();

    await fetchProfile({ profileDispatch: dispatch });
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};
