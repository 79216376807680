import { SnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useContext, useReducer } from 'react';
import reducer from './reducer';

const MessagesStateContext = createContext();
const MessagesDispatchContext = createContext();

const useMessagesState = () => {
  const context = useContext(MessagesStateContext);
  if (!context) {
    throw Error('useMessagesState must be used inside a MessagesProvider');
  }
  return context;
};

const useMessagesDispatch = () => {
  const context = useContext(MessagesDispatchContext);
  if (!context) {
    throw Error('useMessagesDispatch must be used inside a MessagesProvider');
  }
  return context;
};

const initialState = {
  messages: [],
  dialog: {
    open: false,
  },
};

const MessagesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <MessagesStateContext.Provider value={state}>
      <MessagesDispatchContext.Provider value={dispatch}>
        <SnackbarProvider preventDuplicate>{children}</SnackbarProvider>
      </MessagesDispatchContext.Provider>
    </MessagesStateContext.Provider>
  );
};

MessagesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { useMessagesState, useMessagesDispatch, MessagesProvider, MessagesStateContext, MessagesDispatchContext };
