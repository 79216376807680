const DeveloperUserRegistrationOrganism = (theme) => ({
  pageContent: {
    paddingTop: 144,
    paddingBottom: 144,
  },
  callout: {
    '& strong': {
      ...theme.fontWeights.Bold,
    },
  },
  translationBrNoSpace: {
    display: 'block',
    lineHeight: '0px',
    '&::before': {
      content: "'\\00a0 '",
    },
  },
  translationBr: {
    display: 'block',
    lineHeight: '30px',
    '&::before': {
      content: "'\\00a0 '",
    },
  },
  subHeader: {
    marginBottom: 16,
  },
  infoText: {
    fontSize: 24,
    lineHeight: theme.spacing(3.5),
  },
  infoText2Container: {
    marginTop: theme.spacing(2),
  },
  infoText2: {
    fontSize: 20,
    lineHeight: theme.spacing(3.5),
  },
  setupText: {
    ...theme.fontWeights.Medium,
  },
  formContainer: {
    marginTop: 48,
  },
  textPart: {
    display: 'block',
  },
  contactTitle: {
    marginTop: theme.spacing(7),
  },
  contactSubtitle: {
    marginTop: theme.spacing(2),
  },
  field: {
    width: 424,
  },
  form: {
    marginTop: theme.spacing(4),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    '& $field': {
      marginTop: 0,
    },
    '& $field ~ $field': {
      marginTop: theme.spacing(5),
    },
  },
  buttonWrapper: {
    marginTop: theme.spacing(5),
    flexShrink: 0,
  },
  loginContainer: {
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    marginTop: theme.spacing(10),
    paddingTop: theme.spacing(4),
  },
  btnLogin: {
    marginTop: theme.spacing(2),
  },
});

export default DeveloperUserRegistrationOrganism;
