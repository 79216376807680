import services from 'api/services';
import { showLoadingOverlay, hideLoadingOverlay } from 'utils/toggleLoadingOverlay';

/**
 *
 * @typedef {object} DynamicSetting
 */

export const actions = {
  SET_ORGANIZATIONS: 'SET_ORGANIZATIONS',
  SET_ORGANIZATION: 'SET_ORGANIZATION',
  SET_USERS: 'SET_USERS',
  SET_ORGANIZATION_TRUSTED_SUPPLIERS: 'SET_ORGANIZATION_TRUSTED_SUPPLIERS',
  SET_PARTNER_AIRLINES: 'SET_PARTNER_AIRLINES',
};

const setOragnizations = (organizations, counter) => ({
  type: actions.SET_ORGANIZATIONS,
  payload: { organizations, counter },
});
const setOragnization = (payload, etag) => ({ type: actions.SET_ORGANIZATION, payload: { ...payload, etag } });
const setUsers = (payload) => ({ type: actions.SET_USERS, payload });
const setOrganizationUsers = (payload) => ({ type: actions.SET_ORGANIZATION_USERS, payload });
const setOrganizationTrustedSuppliers = (payload) => ({ type: actions.SET_ORGANIZATION_TRUSTED_SUPPLIERS, payload });
const setPartnerAirlinesInternal = (payload) => ({ payload, type: actions.SET_PARTNER_AIRLINES });

export const getOrganizations = async ({ dispatch, status, type }) => {
  try {
    showLoadingOverlay();

    const [{ payload: organizations }, { payload: counterPayload }] = await Promise.all([
      services.getInternalOrganizations({ status, type }),
      services.getInternalOrganizationsCounter({ status }),
    ]);

    dispatch(setOragnizations(organizations, counterPayload));
    return { organizations, counterPayload, status };
  } catch (error) {
    throw error;
  } finally {
    hideLoadingOverlay();
  }
};

export const getOrganization = async ({ dispatch, organizationId }) => {
  try {
    showLoadingOverlay();
    const { payload, headers } = await services.getInternalOrganization({ organizationId });
    dispatch(setOragnization(payload, headers.etag));
  } catch (error) {
    throw error;
  } finally {
    hideLoadingOverlay();
  }
};

export const updateOrganization = async ({ organization, organizationId, etag }) => {
  try {
    showLoadingOverlay();

    await services.updateOrganizationAccounts({
      values: { ...organization },
      organizationId,
      'If-Match': etag,
    });
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const getUsers = async ({ status, dispatch }) => {
  try {
    showLoadingOverlay();

    const { payload } = await services.getInternalUsers({ status });

    dispatch(setUsers(payload));
    return status;
  } catch (error) {
    throw error;
  } finally {
    hideLoadingOverlay();
  }
};

export const getOrganizationUsers = async ({ dispatch, status, organizationId }) => {
  try {
    showLoadingOverlay();

    const { payload } = await services.getOrganizationMembersInternal({ status, organizationId });

    dispatch(setOrganizationUsers(payload));

    return { payload, status };
  } catch (error) {
    throw error;
  } finally {
    hideLoadingOverlay();
  }
};

export const getUserRoles = async ({ organizationType }) => {
  /** @typedef {UM.Response<{id: string, name: string, roleSettings: {subsystemId: string, roleId: string}[]}[]>} MainRolesResponse */
  /** @typedef {UM.Response<{id: string, name: string, roles: {id: string, name: string}[]}[]>} RolesResponse */

  /** @type {[MainRolesResponse, RolesResponse]} */
  const [{ payload: mainRoles }, { payload: systems }] = await Promise.all([
    services.getMainRolesOrganization({ organizationType }),
    services.getUserRoles({ organizationType }),
  ]);

  return { mainRoles, systems };
};

export const getUserDetails = async ({ profileId }) => {
  try {
    showLoadingOverlay();
    const { payload: userDetails, headers } = await services.getUserDetails({ profileId });
    const systems = await getUserRoles({ organizationType: userDetails.organization.type });

    return {
      userDetails,
      ...systems,
      etag: headers.etag,
    };
  } finally {
    hideLoadingOverlay();
  }
};

export const updateUserDetails = async ({ values, etag, profileId }) => {
  try {
    showLoadingOverlay();
    const { payload } = await services.updateUserDetails({ values, 'If-Match': etag, profileId });
    return payload;
  } finally {
    hideLoadingOverlay();
  }
};

export const reSendActivationEmailInternal = async ({ organizationId, profileId }) => {
  try {
    showLoadingOverlay();
    await services.reSendActivationEmailInternal({ organizationId, profileId });
  } finally {
    hideLoadingOverlay();
  }
};

export const deleteUserPendingEmail = async ({ etag, profileId }) => {
  try {
    showLoadingOverlay();
    await services.deleteUserPendingEmail({ 'If-Match': etag, profileId });
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const deleteOwnershipTransfer = async ({ etag, profileId }) => {
  try {
    showLoadingOverlay();
    await services.deleteOwnershipTransferInternal({ 'If-Match': etag, profileId });
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const resetPassword = async ({ profileId, etag }) => {
  try {
    showLoadingOverlay();
    await services.resetPasswordInternal({ profileId, 'If-Match': etag });
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const createUser = ({ organizationId }) => async ({ values }) => {
  try {
    showLoadingOverlay();
    const { payload } = await services.createUserInternal({ values, organizationId });
    return payload;
  } finally {
    hideLoadingOverlay();
  }
};
/**
 *
 * @typedef  {object}        TrustedSuplier
 * @property {string}        id
 * @property {string}        name
 * @property {string}        email
 * @property {string | null} phone
 * @property {string | null} website
 */

export const getOrganizationTrustedSuppliers = async ({ dispatch, organizationId }) => {
  try {
    showLoadingOverlay();

    /** @type {{ payload: TrustedSuplier[] }} */
    const { payload } = await services.getOrganizationTrustedSupplierListInternal({ organizationId });

    dispatch(setOrganizationTrustedSuppliers(payload));
  } catch (error) {
    throw error;
  } finally {
    hideLoadingOverlay();
  }
};

export const getOrganizationPossibleTrustedSuppliers = async ([{ organizationId }]) => {
  const { payload } = await services.getOrganizationPossibleTrustedSupplierListInternal({ organizationId });

  return payload;
};

export const addTrustedSupplier = async ({ organizationId, values }) => {
  try {
    showLoadingOverlay();
    await services.addOrganizationTrustedSupplierInternal({ organizationId, values });
  } catch (error) {
    throw error;
  } finally {
    hideLoadingOverlay();
  }
};

export const removeTrustedSupplier = async ({ dispatch, organizationId, supplierId }) => {
  try {
    showLoadingOverlay();

    await services.removeOrganizationTrustedSupplierInternal({ organizationId, supplierId });

    await getOrganizationTrustedSuppliers({ dispatch, organizationId });
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const getPartnerAirlines = async ({ dispatch, organizationId }) => {
  try {
    showLoadingOverlay();
    const res = await services.getPartnerAirlinesInternal({ organizationId });

    const { payload } = res;
    dispatch(setPartnerAirlinesInternal(payload));
  } catch (err) {
    throw err;
  } finally {
    hideLoadingOverlay();
  }
};

export const getOrganizationSettings = async ({ organizationId }) => {
  try {
    showLoadingOverlay();

    /** @type {UM.Response<{iataCode: string, dataAccessIataCodes: string[], dynamicSettings: DynamicSetting[]}, {etag: string}>} */
    const { payload, headers } = await services.getOrganizationSettings({ organizationId });

    return { ...payload, etag: headers.etag };
  } finally {
    hideLoadingOverlay();
  }
};

export const patchOrganizationSettings = async ({ organizationId, etag, values }) => {
  try {
    showLoadingOverlay();

    /** @type {UM.Response<{iataCode: string, dataAccessIataCodes: string[]}, {etag: string}>} */
    const { payload, headers } = await services.patchOrganizationSettings({ 'If-Match': etag, organizationId, values });

    return { ...payload, etag: headers.etag };
  } finally {
    hideLoadingOverlay();
  }
};
