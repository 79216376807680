const UploaderAxiosMolecule = (theme) => ({
  root: ({ hasError }) => ({
    width: '100%',
    '& .preview': {
      marginTop: theme.spacing(3),
    },
    '& > .add-text': {
      letterSpacing: 0.4,
      marginBottom: 4,
      marginLeft: 4,
      fontSize: '14px',
      lineHeight: '16px',
    },
    '& > .helper-text': {
      fontStyle: 'normal',
      margin: [[theme.spacing(1), 12, 0, 0]],
      '& + p': {
        marginTop: theme.spacing(3),
      },
      letterSpacing: '1px',
      lineHeight: '17px',
    },
    '& .dropzone-root': {
      border: [[1, 'dashed', hasError ? theme.palette.error.main : theme.palette.grey[500]]],
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      height: 120,
      position: 'relative',
      boxSizing: 'border-box',
      transition: 'all .15s linear',
      '-ms-overflow-style': 'none',
      '-moz-scrollbars': 'none',
      'scrollbar-width': 'none',
      '&::-webkit-scrollbar': {
        width: '0 !important',
      },
      '& > .input': {
        display: 'none',
      },
      '& > .text-wrapper': {
        textAlign: 'center',
        fontSize: '16px',
        lineHeight: '30px',
        letterSpacing: '1px',
        '& > .choose-file p': {
          textDecoration: 'underline',
          ...theme.fontWeights.Medium,
        },
      },
    },
  }),
});

export default UploaderAxiosMolecule;
