/* eslint-disable no-underscore-dangle */
const KEYCLOAK_URL = window._env_.REACT_APP_KEYCLOAK_URL;
const KEYCLOAK_REALM = window._env_.REACT_APP_KEYCLOAK_REALM;
const KEYCLOAK_CLIENT_ID = window._env_.REACT_APP_KEYCLOAK_CLIENT_ID;
const API_BASE_URL = window._env_.REACT_APP_API_BASE_URL;
const NODE_ENV = window._env_.REACT_APP_NODE_ENV;
const GA_TRACKING_ID = window._env_.REACT_APP_GA_TRACKING_ID;
const ENV_LABEL = window._env_.REACT_APP_ENV_LABEL;
const USER_MANAGEMENT_WEB_API_URL = window._env_.REACT_APP_USER_MANAGEMENT_WEB_API_URL;
const NOTIFICATION_SERVICE_URL = window._env_.REACT_APP_NOTIFICATION_SERVICE_URL;
const USER_MANAGER_BLOB_URL_PREFIX=window._env_.REACT_APP_USER_MANAGER_BLOB_URL_PREFIX;

export {
  KEYCLOAK_URL,
  KEYCLOAK_REALM,
  KEYCLOAK_CLIENT_ID,
  API_BASE_URL,
  NODE_ENV,
  GA_TRACKING_ID,
  ENV_LABEL,
  USER_MANAGEMENT_WEB_API_URL,
  NOTIFICATION_SERVICE_URL,
  USER_MANAGER_BLOB_URL_PREFIX,
};
