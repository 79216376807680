import { useMemo, useCallback, useContext } from 'react';
import {
  useProfileState,
  useProfileDispatch,
} from '@control-tower/aerq-navigation-library/dist/auth/state/Profile/context';
import { confirmPendingOwnership } from 'state/Profile/actions';
import { useAsync } from 'react-async';
import qs from 'query-string';
import { KeycloakContext } from '@control-tower/aerq-navigation-library';
import { useLocation } from 'react-router-dom';

const usePedndingOwnershipConfirmation = () => {
  const { profile } = useProfileState();
  const profileDispatch = useProfileDispatch();
  const location = useLocation();

  const token = useMemo(() => {
    const parsedQuery = qs.parse(location.search);

    if (!parsedQuery.token) {
      return null;
    }

    return parsedQuery.token;
  }, [location.search]);

  const { isLoading } = useAsync({
    promiseFn: confirmPendingOwnership,
    token,
    dispatch: profileDispatch,
  });

  /** @type {import('keycloak-js').KeycloakInstance} */
  const keycloak = useContext(KeycloakContext);

  const handleHomeButtonClick = useCallback(async () => {
    try {
      /**
       * force token refresh
       * @see https://github.com/keycloak/keycloak/blob/master/adapters/oidc/js/src/main/resources/keycloak.js line: 623
       */
      await keycloak.updateToken(-1);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('Failed to update the token', err);
      keycloak.clearToken();
    }
    sessionStorage.removeItem('kc_token');
    sessionStorage.removeItem('kc_refreshToken');

    window.location.replace('/');
  }, [keycloak]);

  return { isLoading, handleHomeButtonClick, profile };
};

export { usePedndingOwnershipConfirmation as default, usePedndingOwnershipConfirmation };
