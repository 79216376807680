import { ExportStatus } from 'common/enums';
import {
  SET_ITEMS,
  SET_FAILED_ORG_ID,
  SET_ITEM_DOWNLOADED,
  SET_ACTIVE_EXPORT_CHECKED_ON_INIT,
  SET_ITEM_FAILED,
  REMOVE_DOWNLOADED_ITEM,
} from './actions';

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ITEMS: {
      // Replace list coming from payload, keep items with downloaded and failed status
      return {
        ...state,
        items: [
          ...payload,
          ...state.items.filter(
            (item) => item.status === ExportStatus.DOWNLOADED || item.status === ExportStatus.FAILED_HANDLED
          ),
        ],
        deletedItems: [],
        failedOrgIds: [],
      };
    }
    case SET_ACTIVE_EXPORT_CHECKED_ON_INIT: {
      return {
        ...state,
        activeExportsCheckedOnInit: true,
      };
    }
    case SET_FAILED_ORG_ID: {
      return {
        ...state,
        failedOrgIds: [...state.failedOrgIds, payload],
      };
    }
    case SET_ITEM_DOWNLOADED: {
      const { id } = payload;
      const items = [...state.items].map((item) => {
        if (item.id === id) {
          return {
            ...item,
            status: ExportStatus.DOWNLOADED,
          };
        }
        return {
          ...item,
        };
      });

      return {
        ...state,
        items,
      };
    }
    case REMOVE_DOWNLOADED_ITEM: {
      return {
        ...state,
        items: [...state.items.filter((item) => item.id !== payload)],
      };
    }
    case SET_ITEM_FAILED: {
      const { id } = payload;
      const items = [...state.items].map((item) => {
        if (item.id === id) {
          return {
            ...item,
            status: ExportStatus.FAILED_HANDLED,
          };
        }
        return {
          ...item,
        };
      });

      return {
        ...state,
        items,
      };
    }
    default:
      return state;
  }
};

export default reducer;
