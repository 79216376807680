import PropTypes from 'prop-types';
import { createContext, useContext, useReducer } from 'react';
import reducer from './reducer';

const VersionInfoStateContext = createContext();
const VersionInfoDispatchContext = createContext();

const useVersionInfoState = () => {
  const context = useContext(VersionInfoStateContext);
  if (!context) {
    throw Error('useVersionInfoState must be used inside VersionInfoProvider');
  }
  return context;
};

const useVersionInfoDispatch = () => {
  const context = useContext(VersionInfoDispatchContext);
  if (!context) {
    throw Error('useVersionInfoDispatch must be used inside a VersionInfoProvider');
  }
  return context;
};

const initialState = {
  systemName: '',
  systemVersion: '',
  backendVersion: '',
};

// use state
const VersionInfoProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <VersionInfoStateContext.Provider value={state}>
      <VersionInfoDispatchContext.Provider value={dispatch}>{children}</VersionInfoDispatchContext.Provider>
    </VersionInfoStateContext.Provider>
  );
};

export { useVersionInfoState, useVersionInfoDispatch, VersionInfoProvider, VersionInfoStateContext };

VersionInfoProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
