const PreviewElementMolecule = (theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    minHeight: 95,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.common.white,
    border: [[1, 'solid', theme.palette.grey[300]]],
    borderRadius: 4,
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
    position: 'relative',
    '&$error > $textWrapper': {
      width: 'calc(100% - 48px)',
      paddingLeft: '24px !important',
    },
  },
  disabled: {
    '&:hover': {
      backgroundColor: 'unset',
    },
  },
  size: {
    overflowWrap: 'break-word',
    letterSpacing: '1px',
    lineHeight: '17px',
  },
  sizeError: {
    color: theme.error['800'],
    overflowWrap: 'break-word',
    letterSpacing: '1px',
    lineHeight: '17px',
  },
  name: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginBottom: 0,
    lineHeight: '28px',
    letterSpacing: '1px',
  },
  nameError: {
    color: theme.error['800'],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginBottom: 0,
    ...theme.fontWeights.Medium,
    lineHeight: '28px',
    letterSpacing: '1px',
  },
  imageWrapper: {
    display: 'inline-flex',
    width: 95,
    height: 95,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    '& > *': {
      width: '100%',
    },
  },
  removeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: 2,
    '& svg': {
      width: 16,
      height: 16,
    },
    '& [stroke]': {
      stroke: theme.palette.grey['800'],
    },
  },
  progressBar: {
    marginTop: 7,
    width: '60%',
    background: theme.palette.grey['200'],
    '& .MuiLinearProgress-bar': {
      background: `linear-gradient(90deg, ${theme.palette.primary.light} -5%, ${theme.palette.primary.dark} 100%)`,
    },
  },
  warningIcon: { fill: theme.error['800'], marginLeft: theme.spacing(3), alignSelf: 'center' },
  textWrapper: {
    width: 'calc(100% - 95px)',
    padding: [[theme.spacing(2), theme.spacing(5), theme.spacing(2), theme.spacing(2)]],
    flexGrow: 1,
  },
  error: {},
});

export default PreviewElementMolecule;
