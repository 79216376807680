const AddAirlineMolecule = (theme) => ({
  root: {
    '& .modal-paper': {
      height: '92vh',
    },
  },

  maxWidthWrapper: {
    padding: [[theme.spacing(1), 0]],
    maxWidth: 730,
  },
  title: {
    maxWidth: 730,
    flexShrink: 0,
    marginBottom: theme.spacing(6),
    color: theme.palette.primary.main,
    '@supports (background-clip: text) or (-webkit-background-clip: text)': {
      background: `linear-gradient(90deg, ${theme.palette.primary.light} -5%, ${theme.palette.primary.dark} 100%)`,
      '-webkit-background-clip': 'text',
      backgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
  },
  subtitle: {
    marginBottom: theme.spacing(5),
  },
  contactTitle: {
    marginTop: theme.spacing(7),
  },
  contactSubtitle: {
    marginTop: theme.spacing(2),
  },
  field: {
    width: 424,
  },
  capitalizedField: {
    '& input': {
      textTransform: 'uppercase',
    },
  },
  form: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    '& $field': {
      marginTop: 0,
    },
    '& $field ~ $field': {
      marginTop: theme.spacing(5),
    },
  },
  scrollableContentWrapper: {
    display: 'flex',
    overflow: 'hidden',
  },
  scrollableContent: {
    flexGrow: 1,
    width: '100%',
  },
  buttonWrapper: {
    marginTop: theme.spacing(5),
    flexShrink: 0,
  },
});

export { AddAirlineMolecule as default };
