const UserActivationErrorPageMolecule = (theme) => ({
  root: {
    width: 740,
    margin: 'auto',
    '& h5': {
      color: theme.palette.grey[700],
    },
  },
});

export { UserActivationErrorPageMolecule as default };
