const VersionFooterMolecule = {
  root: {
    padding: [[24, 0, 16]],
    textAlign: 'right',
    fontSize: '85%',
    flexShrink: 0,
  },
  mainVersion: {
    fontWeight: 'bold',
    marginRight: 5,
  },
};

export default VersionFooterMolecule;
