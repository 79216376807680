const MembersContentMolecule = (theme) => ({
  table: {
    marginTop: theme.spacing(4),
  },
  headerRow: {
    fontWeight: 'normal',
    borderBottom: [[2, 'solid', theme.palette.grey[400]]],
    height: 72,
  },
  dataRow: {
    '& .MuiTableCell-root': {
      fontWeight: 300,
      color: theme.palette.common.black,
    },
    '& $boldLabel': {
      fontWeight: 600,
    },
  },
  hideContent: {
    visibility: 'hidden',
  },
  notClickableRow: {
    cursor: 'default',
  },
  boldLabel: {},
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  addUserBtn: {
    flexShrink: 0,
    marginLeft: theme.spacing(1),
    alignSelf: 'flex-start',
  },
});

export { MembersContentMolecule as default };
