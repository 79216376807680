/**
 * There is no separation in style because the uploader component will be changed to a new one (with axios)
 */
const FileUploaderMolecule = {
  icon: {
    '& .image': {
      cursor: 'normal',
    },
    '& .text-wrapper': {
      paddingLeft: '0 !important',
    },
  },
};

export default FileUploaderMolecule;
