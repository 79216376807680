import { Header } from 'view/components/header';
import DesignSystem from 'theme/design_system/designSystem';
import { useTranslation } from 'react-i18next';
import {
  CenteredContentTemplate,
  CustomButton,
  PageContent,
  PageTemplate,
  SubHeader,
} from '@control-tower/aerq-ui-library';
import { usePedndingOwnershipConfirmation } from './PendingOwnershipConfirmation.hook';

const useStyles = DesignSystem.organisms.PendingOwnershipConfirmation();

const PendingOwnershipConfirmation = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isLoading, handleHomeButtonClick, profile } = usePedndingOwnershipConfirmation();

  if (isLoading) return null;

  return (
    <PageTemplate>
      <Header showMenusAndOrg={false} />
      <PageContent className={classes.pageContent} backgroundType="gradient-large" backgroundHeight={955}>
        <CenteredContentTemplate gridTemplateNumbers={[2, 8, 2]}>
          <SubHeader
            lineHeight="88px"
            title={t('user.activation.ownershipConfirmationTitle', { organization: profile.organization.name })}
            className={classes.subHeader}
          />
          <CustomButton onClick={handleHomeButtonClick} className={classes.btnGoToHome} data-testid="btn-go-to-home">
            {t('user.buttons.goToHome')}
          </CustomButton>
        </CenteredContentTemplate>
      </PageContent>
    </PageTemplate>
  );
};

PendingOwnershipConfirmation.propTypes = {};

export default PendingOwnershipConfirmation;
