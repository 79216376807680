/**
 * There is no separation in style because the uploader component will be changed to a new one (with axios)
 */
const FittedImageMolecule = {
  img: (props) => ({
    objectFit: `${props.fit}`,
    display: 'block',
    width: '100%',
    height: '100%',
  }),
  imgNoFit: {
    width: '100%',
  },
  fallbackStyle: (props) => ({
    backgroundImage: `url(${props.src})`,
    backgroundSize: `${props.fit}`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
    height: '100%',
  }),
};

export default FittedImageMolecule;
