import { ADD_MESSAGE, REMOVE_MESSAGE, HIDE_ERROR_DIALOG, SHOW_ERROR_DIALOG } from './actions';

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_MESSAGE: {
      return {
        ...state,
        messages: [...state.messages, payload],
      };
    }
    case REMOVE_MESSAGE: {
      const { messages } = state;
      const { key } = payload;
      return { ...state, messages: messages.filter((message) => message.key !== key) };
    }

    case SHOW_ERROR_DIALOG: {
      const { errorCode, messages, open } = payload;
      const errorCodeFamily = errorCode ? `${String(errorCode).charAt(0)}xx` : 'unknown';
      return {
        ...state,
        dialog: {
          errorCode,
          errorCodeFamily,
          messages,
          open,
        },
      };
    }

    case HIDE_ERROR_DIALOG: {
      return {
        ...state,
        dialog: {
          open: false,
        },
      };
    }
    default:
      return state;
  }
};
export default reducer;
