const AirlineSettingsMolecule = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  save: { felxShirnk: 0 },
  field: {
    maxWidth: theme.spacing(53),
    marginTop: theme.spacing(6),
    '& ~ $field': {
      marginTop: theme.spacing(5),
    },
  },
  uppercase: {
    '& input': {
      textTransform: 'uppercase',
    },
  },
  group: {
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(9),
  },
  dynField: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  dynFieldLabel: {
    fontSize: theme.fontSizes.Normal,
    lineHeight: '28px',
  },
});

export { AirlineSettingsMolecule as default };
