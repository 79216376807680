import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';
import DesignSystem from 'theme/design_system/designSystem';
import { Header } from 'view/components/header';
import UnauthorizedComponent from 'view/components/error/UnauthorizedComponent';
import { Label, TextLink, ChevronRight } from '@control-tower/aerq-ui-library';
import { checkPermissions } from '@control-tower/aerq-navigation-library/dist/auth/state/Profile/actions';
import { EmailChangeErrorPage } from 'view/User/EmailChange';
import { PendingOwnershipConfirmErrorPage } from 'view/User/PendingOwnershipConfirmation';
import UserActivationErrorPage from './UserActivationErrorPage';

const useStyles = DesignSystem.molecules.ErrorPage();

const ErrorPage = (props) => {
  const { message } = props;

  const { code } = message || {};

  switch (code) {
    case 'NO_PERMISSION':
      return <UnauthorizedComponent />;
    case 'NOT_ACTIVE_PROFILE_ACTIVATION_TOKEN_ERROR':
    case 'USER_TOKEN_MISMATCH':
      return <UserActivationErrorPage errorCode={message.code} />;
    case 'NO_PENDING_OWNER_CHANGE':
      return <PendingOwnershipConfirmErrorPage />;
    case 'NO_PENDING_EMAIL_CHANGE':
      return <EmailChangeErrorPage />;

    default:
      return <ErrorPageContent {...props} />;
  }
};

const ErrorPageContent = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { title, errorCode, statusCode, description } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const { isLoading } = useAsync({
    promiseFn: checkPermissions,
    statusCode,
  });

  const handleClick = () => {
    window.location.replace('/');
  };

  if (!isLoading) {
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <Header showProfileMenu={false} />
        </div>
        <div className={classes.content}>
          <Label className={classes.title} title={title} type="primary" variant="h2" />
          <Label className={classes.errorCode} title={errorCode} type="grey" color="600" />

          <Label className={classes.text} title={description} />
          <TextLink onClick={handleClick} color="primary" underline="hover" endIcon={<ChevronRight />}>
            {t('errorDialog.homePage')}
          </TextLink>
        </div>
      </div>
    );
  }
  return <Fragment />;
};
ErrorPage.propTypes = {
  title: PropTypes.string.isRequired,
  errorCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  description: PropTypes.node.isRequired,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.shape()]),
  statusCode: PropTypes.number,
};

ErrorPage.defaultProps = {
  statusCode: null,
  message: null,
};

ErrorPageContent.propTypes = {
  title: PropTypes.string.isRequired,
  errorCode: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  statusCode: PropTypes.number,
};

ErrorPageContent.defaultProps = {
  statusCode: null,
};

export default ErrorPage;
