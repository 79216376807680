const FooterMolecule = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.footer,
    height: 300,
    paddingTop: 80,
  },
  contact: {
    padding: [[0, 12]],
    display: 'flex',
    alignItems: 'center',
  },
  contactWrapper: {
    marginLeft: 'auto',
    display: 'inline-flex',
    alignContent: 'center',
    '& a': {
      textDecoration: 'none',
    },
    '& > span:first-of-type': {
      marginRight: theme.spacing(12),
    },
  },
  legal: {
    marginTop: 50,
    padding: [[0, 12]],
    paddingTop: theme.spacing(3),
    borderTop: [[1, 'solid', theme.palette.grey[300]]],
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '& .MuiTypography-root': {
      fontSize: 18,
    },
    '& a:first-of-type': {
      marginRight: theme.spacing(10),
    },
    '& a': {
      textDecorationColor: theme.palette.grey[500],
    },
  },
  phoneTitle: {
    ...theme.fontWeights.Semibold,
  },
  emailTitle: {
    ...theme.fontWeights.Semibold,
  },
  phone: {
    ...theme.fontWeights.Semibold,
    color: theme.palette.common.black,
  },
  email: {
    color: theme.palette.primary.main,
    ...theme.fontWeights.Semibold,
  },
  '@supports (background-clip: text) or (-webkit-background-clip: text)': {
    email: {
      background: `linear-gradient(90deg, ${theme.palette.primary.light} -5%, ${theme.palette.primary.dark} 100%)`,
      backgroundClip: 'text',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      color: 'transparent',
    },
  },
});

export default FooterMolecule;
