const PartnerAirlinesMolecule = (theme) => ({
  root: {
    marginTop: 50,
    minHeight: 250,
  },
  cardsWrapper: {
    marginTop: theme.spacing(4),
  },
  card: {
    marginTop: 0,
    '& ~ $card': {
      marginTop: theme.spacing(3.5),
    },
  },
  hide: {
    visibility: 'hidden',
  },
  description: {
    marginTop: theme.spacing(3),
    ...theme.fontWeights.Normal,
  },
});

export { PartnerAirlinesMolecule as default };
