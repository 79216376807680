const SelectOptionAtom = (theme) => ({
  root: {
    '&&': {
      width: '100%',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      padding: [[theme.spacing(2), 28]],
      alignItems: 'flex-start',
    },
  },
  name: {
    ...theme.text.small,
    lineHeight: 'normal',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  website: {
    ...theme.text.small,
    lineHeight: 'normal',
    marginTop: 6,
  },
});

export { SelectOptionAtom as default };
