const trimWhitespaces = (values) => {
  if (!values || ['number', 'boolean', 'bigint', 'symbol'].includes(typeof values)) {
    return values;
  }

  if (typeof values === 'string') {
    return values.trim();
  }

  if (Array.isArray(values)) {
    return values.map((element) => trimWhitespaces(element));
  }

  const result = JSON.parse(JSON.stringify(values));

  Object.entries(result).forEach(([key, value]) => {
    result[key] = trimWhitespaces(value);
  });

  return result;
};

export default trimWhitespaces;
