const UserActivationOrganism = (theme) => ({
  pageContent: {
    paddingTop: 144,
    paddingBottom: 144,
  },
  subHeader: {
    marginBottom: 16,
  },
  infoText: {
    fontSize: 18,
    lineHeight: theme.spacing(3.5),
    maxWidth: 525,
  },
  form: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    '& $field, & .password-field-container': {
      width: 424,
    },
  },
  field: {
    marginTop: 0,
    '& ~ $field, & ~ .password-field-container': {
      marginTop: theme.spacing(5),
    },
  },
  btnSave: {
    marginTop: theme.spacing(4),
  },
});

export default UserActivationOrganism;
