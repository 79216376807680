import { actions } from './actions';

const initialState = {
  organizations: [],
  organizationsCounter: {
    count: 0,
  },
  organization: {
    users: [],
  },
  users: [],
  organizationEtag: null,
  partnerAirlines: [],
  trustedSuppliers: [],
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case actions.SET_ORGANIZATIONS: {
      const { organizations, counter } = payload;
      return { ...state, organizations: [...organizations], organizationsCounter: counter };
    }
    case actions.SET_ORGANIZATION: {
      const { etag, ...data } = payload;
      return { ...state, organization: { ...data }, organizationEtag: etag };
    }
    case actions.SET_USERS: {
      return { ...state, users: [...payload] };
    }
    case actions.SET_ORGANIZATION_USERS: {
      return { ...state, organization: { ...state.organization, users: [...payload] } };
    }
    case actions.SET_ORGANIZATION_TRUSTED_SUPPLIERS: {
      return { ...state, trustedSuppliers: [...payload] };
    }
    case actions.SET_PARTNER_AIRLINES: {
      return {
        ...state,
        partnerAirlines: [...payload],
      };
    }
    default:
      return state;
  }
};

export { initialState, reducer };
