/**
 * There is no separation in style because the uploader component will be changed to a new one (with axios)
 */
const PreviewsMolecule = (theme) => ({
  root: {
    '& > .preview': {
      '&:first-of-type': {
        marginTop: 0,
      },
      marginTop: theme.spacing(1),
    },
    '& > .label-text': {
      fontSize: 14,
      letterSpacing: 0.4,
      marginTop: 32,
      marginBottom: 4,
    },
  },
});

export default PreviewsMolecule;
