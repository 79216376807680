import { useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useMessagesState } from 'state/Messages/context';
import ErrorPage from './ErrorPage';

const BlockingErrorView = () => {
  const { dialog } = useMessagesState();
  const { t } = useTranslation();
  const location = useLocation();

  const title = useMemo(
    () =>
      t([
        `errorDialog.title.${dialog.errorCode}`,
        `errorDialog.title.${dialog.errorCodeFamily}`,
        'errorDialog.title.default',
      ]),
    [t, dialog.errorCode, dialog.errorCodeFamily]
  );
  const description = useMemo(
    () =>
      t(
        [
          `errorDialog.description.${dialog.errorCode}`,
          `errorDialog.description.${dialog.errorCodeFamily}`,
          'errorDialog.description.default',
        ],
        { interpolation: { escapeValue: false } }
      ),
    [dialog.errorCode, dialog.errorCodeFamily, t]
  );

  const errorCode = useMemo(
    () => (dialog.errorCode ? `${t('errorDialog.errorCode.default')}: ${dialog.errorCode}` : ''),
    [dialog.errorCode, t]
  );

  const message = useMemo(() => (dialog.messages && dialog.messages.length > 0 ? dialog.messages[0] : null), [
    dialog.messages,
  ]);

  // reload the app if it crashes
  useEffect(() => {
    if (!dialog.errorCode) {
      // eslint-disable-next-line no-console
      console.log('CONTROL TOWER - > UNKNOWN CRASH');
      // window.location.pathname = location.pathname;
    }
  }, [location, dialog.errorCode]);

  return (
    <ErrorPage
      title={title}
      description={description}
      statusCode={dialog.errorCode}
      errorCode={errorCode}
      message={message}
    />
  );
};

export default BlockingErrorView;
