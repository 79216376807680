/* eslint-disable import/no-cycle */
import services from '../../api/services';
import packageJson from '../../../package.json';

export const SET_BACKEND_VERSION = 'SET_BACKEND_VERSION';

export const setBackendVersion = (payload) => {
  return {
    payload,
    type: SET_BACKEND_VERSION,
  };
};

export const fetchBackendInfo = async ({ dispatch }) => {
  const result = await services.getInfo();

  const payload = { ...result.payload, frontendVersion: packageJson.version };

  dispatch(setBackendVersion(payload));

  return result;
};
