/* eslint-disable no-underscore-dangle */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useAuthConfState } from '@control-tower/aerq-navigation-library/dist/auth/state/Auth/authContext';
import { SubMenu } from '@control-tower/aerq-ui-library';

const useNavConfig = (config, userRoles = []) => {
  const { pathname } = useLocation();
  const navConfig = useMemo(
    () =>
      config
        .filter(({ requiredRole }) => (requiredRole ? userRoles.includes(requiredRole) : true))
        .map((item) => {
          return { ...item, active: pathname.includes(item.url) };
        }),
    [config, pathname, userRoles]
  );
  return navConfig;
};

/**
 *
 * @typedef {object} SubmenuConfigItem
 * @property {string} name
 * @property {string} url
 * @property {boolean} [active]
 * @property {string} [testId]
 * @property {string} [primaryColor]
 * @property {string} [className]
 */

/**
 * @typedef {object} SubMenuProps
 * @property {SubmenuConfigItem[]} config
 * @property {string|number} [height]
 */

/**
 * @type {React.FC<SubMenuProps>}
 */
const SubMenuController = ({ config, height }) => {
  const authState = useAuthConfState();
  const navConfig = useNavConfig(config, authState.authData.roles);

  const location = useLocation();

  return <SubMenu location={location} Link={Link} config={navConfig} height={height} />;
};

SubMenuController.propTypes = {
  config: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SubMenuController.defaultProps = {
  height: 48,
};

export default SubMenuController;
