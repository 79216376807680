import WarningIcon from 'assets/svg/icon-warning.svg';

const SideMenuItemMolecule = (theme) => ({
  menuItem: {
    ...theme.text.small,
    padding: 0,
    marginBottom: 18,
    marginTop: '0 !important',
    height: 26,
    minHeight: 26,
    opacity: 1,
    color: theme.palette.grey['600'],
    letterSpacing: 1,
    justifyContent: 'flex-start',
    textAlign: 'left',
    maxWidth: '100%',
    '&:hover': {
      color: theme.palette.common.black,
      '& svg path': {
        fill: theme.palette.common.black,
      },
    },

    '&.Mui-selected.ellipsis:before': {
      content: "'\\2026'",
      position: 'absolute',
      right: -1,
      top: 0,
      color: theme.palette.primary.main,
      lineHeight: '26px',
      ...theme.fontWeights.Semibold,
    },

    '& .MuiTab-wrapper': {
      display: 'inline-block',
      width: 'auto',
      maxWidth: '100%',
      textTransform: 'uppercase',
      textAlign: 'left',
      ...theme.fontWeights.Medium,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      '& > *:first-child': {
        marginBottom: 0,
      },
      '& svg': {
        width: 16,
        height: 16,
        marginRight: 8,
        position: 'relative',
        top: -1,
        verticalAlign: 'middle',
      },
      '& svg path': {
        fill: theme.palette.grey['600'],
      },
      '& .truncate-label': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
      '& .right-label': {
        paddingLeft: theme.spacing(1),
        flexGrow: 1,
        textAlign: 'right',
      },
    },
    '&.Mui-selected .MuiTab-wrapper': {
      color: theme.palette.primary.main,
      background:
        window && window.matchMedia && !window.matchMedia('(-ms-high-contrast: none)').matches
          ? `linear-gradient(90deg, ${theme.palette.primary.light} -5%, ${theme.palette.primary.dark} 100%)`
          : 'transparent',
      '-webkit-background-clip': 'text',
      textFillColor: 'transparent',
      fontWeight: 600,
    },
    '&.Mui-selected .MuiTab-wrapper svg > *': {
      fill: 'url(#svg_primary_linear_gradient_fill) !important',
    },
    '&.Mui-selected .MuiTab-wrapper svg > *[stroke]': {
      stroke: 'url(#svg_primary_linear_gradient_fill) !important',
    },
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  },
  addMenuItem: {
    color: theme.palette.grey['500'],
    '& svg path': {
      fill: theme.palette.grey['500'],
    },
  },
  menuItemError: {
    overflow: 'visible',
    position: 'relative',
    maxWidth: 'calc(100% + 24px)',
    width: 'calc(100% + 24px)',
    '&.Mui-selected .MuiTab-wrapper': {
      color: theme.palette.error.main,
      textFillColor: theme.palette.error.main,
    },
    '&.Mui-selected.ellipsis:before': {
      display: 'none',
    },
    '&.Mui-selected .MuiTab-wrapper svg > *[fill]': {
      fill: theme.palette.error.main,
    },
    '&.Mui-selected .MuiTab-wrapper svg > *[stroke]': {
      stroke: theme.palette.error.main,
    },
    '& .MuiTab-wrapper': {
      color: theme.palette.error.main,
      position: 'relative',
      paddingRight: 24,
      '&::after': {
        content: "''",
        display: 'block',
        position: 'absolute',
        top: '50%',
        right: 0,
        marginTop: -8,
        width: 16,
        height: 16,
        backgroundImage: `url(${WarningIcon})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 16,
      },
    },
  },
  menuItemNormalText: {
    '& .MuiTab-wrapper': {
      textTransform: 'none',
    },
  },
  striketrough: {
    '& .MuiTab-wrapper': {
      position: 'relative',
    },
    '& .MuiTab-wrapper:before': {
      content: "''",
      display: 'block',
      width: '100%',
      height: 1,
      position: 'absolute',
      top: 'calc(50% - 0.5px)',
      left: 0,
      backgroundColor: theme.palette.grey['600'],
    },
    '&:hover .MuiTab-wrapper:before': {
      backgroundColor: theme.palette.common.black,
    },
    '&.Mui-selected .MuiTab-wrapper:before': {
      backgroundColor: 'transparent',
      background: `linear-gradient(178.89deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.dark} 98.64%)`,
    },
  },
});

export default SideMenuItemMolecule;
