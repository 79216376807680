const SideMenuSectionTitleMolecule = (theme) => ({
  sectionTitle: {
    color: theme.palette.grey['500'],
    lineHeight: '18px',
    minHeight: theme.spacing(2.5),
    marginBottom: 20,
    padding: `0`,
    fontSize: theme.fontSizes.Small,
    ...theme.fontWeights.Medium,
    letterSpacing: '1px',

    '&.MuiTab-textColorInherit.Mui-disabled': {
      opacity: '1',
      pointerEvents: 'all',
    },
    '&.MuiTab-root': {
      textAlign: 'left',
      textTransform: 'none',
    },
    '& span.MuiTab-wrapper': {
      display: 'block',
      ...theme.text.h6,
    },
  },
  sectionTitleBorder: {
    marginTop: theme.spacing(1),
    paddingTop: 30,
    borderTopStyle: 'solid',
    borderTopColor: theme.palette.grey['300'],
    borderTopWidth: '1px',
  },
  sectionDelimiter: {
    borderTop: [['solid', 1, theme.palette.grey['300']]],
    minHeight: 'auto',
    padding: 0,
    margin: [[theme.spacing(2.25), 0, theme.spacing(4.5)]],
    '&.MuiTab-textColorInherit.Mui-disabled': {
      opacity: 1,
    },
  },
});

export default SideMenuSectionTitleMolecule;
