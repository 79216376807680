const TrustedSuplierCardMolecule = (theme) => ({
  root: {
    position: 'relative',
    borderRadius: theme.spacing(0.5),
    border: [[1, 'solid', theme.palette.grey[400]]],
  },
  button: {
    position: 'absolute',
    top: `calc( 21px - ${theme.spacing(0.5)})`,
    right: `calc( 19px - ${theme.spacing(0.5)})`,
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(0.5),
  },
  content: {
    padding: theme.spacing(4),
    overflowWrap: 'break-word',

    '& .rowCell ~ .rowCell ~ .rowCell ': {
      marginTop: 22,
    },
  },
});

export { TrustedSuplierCardMolecule as default };
