const ErrorPageMolecule = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: theme.background.grey,
  },
  content: {
    alignSelf: 'left',
    textAlign: 'left',
    margin: '7% 0',
    marginLeft: '165px',
    marginTop: '80px',
    marginBottom: '24px',
  },
  title: {
    margin: '8px 0',
    letterSpacing: '1px',
    color: theme.palette.primary.main,
    display: 'inline-block',
    background:
      window && window.matchMedia && !window.matchMedia('(-ms-high-contrast: none)').matches
        ? `linear-gradient(90deg, ${theme.palette.primary.light} -5%, ${theme.palette.primary.dark} 100%)`
        : 'transparent',
    '-webkit-background-clip': 'text',
    textFillColor: 'transparent',
  },
  errorCode: {
    margin: '16px 0',
    fontSize: theme.fontSizes.Large,
    ...theme.fontWeights.Normal,
    lineHeight: '28px',
    letterSpacing: '1px',
  },
  text: {
    whiteSpace: 'pre-line',
    marginTop: '40px',
    marginBottom: '24px',
    fontSize: theme.fontSizes.Normal,
    ...theme.fontWeights.Normal,
    lineHeight: '28px',
    color: '#495A63',
    letterSpacing: '1px',
  },
});

export default ErrorPageMolecule;
