const OrganizationDetailsMolecule = (theme) => ({
  titleContainer: {
    display: 'flex',
    paddingTop: theme.spacing(5),
  },
  title: {
    alignSelf: 'flex-end',
    marginBottom: '48px',
  },
  pageTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subHeader: {
    marginBottom: theme.spacing(1),
    '& h5': {
      marginBottom: 0,
    },
    '& h5:first-letter': {
      textTransform: 'capitalize',
    },
  },
  pageContent: ({ isOnUserEditForm }) => ({
    marginTop: isOnUserEditForm ? 0 : theme.spacing(8),
  }),
  menuContainer: {
    marginTop: '6px',
    padding: 0,
  },
});

export default OrganizationDetailsMolecule;
