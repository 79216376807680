/* eslint-disable import/no-cycle */
import createService from './provider';

const serviceConfig = {
  hello: {
    url: '/hello',
    method: 'GET',
    authRequired: false,
    paramTypes: {
      name: 'query',
    },
  },
  getInfo: {
    url: '/info',
    method: 'GET',
    authRequired: true,
  },
  updateProfile: {
    url: '/profile',
    method: 'PATCH',
    authRequired: true,
    paramTypes: {
      values: 'data',
      'If-Match': 'header',
    },
  },
  deleteProfilePendingEmail: {
    url: '/profile/email-change',
    method: 'DELETE',
    authRequired: true,
    paramTypes: {
      'If-Match': 'header',
    },
  },
  updateMyOrganization: {
    method: 'PATCH',
    url: '/organization',
    authRequired: true,
    paramTypes: {
      values: 'data',
      'If-Match': 'header',
    },
  },
  // COTOLSY-1823
  // confirmPendingOwnership: {
  //   method: 'POST',
  //   url: '/owner-change-activation',
  //   authRequired: true,
  //   paramTypes: {
  //     token: 'query',
  //   },
  // },
  getInternalOrganizations: {
    url: '/accounts/organizations',
    method: 'GET',
    authRequired: true,
    paramTypes: {
      status: 'query',
      type: 'query',
    },
  },
  getInternalOrganizationsCounter: {
    url: '/accounts/organization-counts',
    method: 'GET',
    authRequired: true,
    paramTypes: {
      status: 'query',
    },
  },
  getInternalOrganization: {
    url: '/accounts/organizations/{organizationId}',
    method: 'GET',
    authRequired: true,
  },
  updateOrganizationAccounts: {
    url: '/accounts/organizations/{organizationId}',
    method: 'PATCH',
    authRequired: true,
    paramTypes: {
      values: 'data',
      'If-Match': 'header',
    },
  },
  getInternalUsers: {
    url: '/accounts/profiles',
    method: 'GET',
    authRequired: true,
    paramTypes: {
      status: 'query',
    },
  },
  getOrganizationMembersInternal: {
    url: '/accounts/organizations/{organizationId}/members',
    authRequired: true,
    paramTypes: {
      status: 'query',
    },
  },
  getUserRoles: {
    method: 'GET',
    url: '/accounts/subsystem-roles',
    authRequired: true,
    paramTypes: {
      organizationType: 'query',
    },
  },
  getUserDetails: {
    method: 'GET',
    url: '/accounts/profiles/{profileId}',
    authRequired: true,
  },
  updateUserDetails: {
    method: 'PATCH',
    url: '/accounts/profiles/{profileId}',
    authRequired: true,
    paramTypes: {
      values: 'data',
      'If-Match': 'header',
    },
  },
  deleteUserPendingEmail: {
    url: '/accounts/profiles/{profileId}/email-change',
    method: 'DELETE',
    authRequired: true,
    paramTypes: {
      'If-Match': 'header',
    },
  },
  deleteOwnershipTransferInternal: {
    url: 'accounts/profiles/{profileId}/owner-change',
    method: 'DELETE',
    authRequired: true,
    paramTypes: {
      'If-Match': 'header',
    },
  },
  createUserInternal: {
    method: 'POST',
    url: '/accounts/organizations/{organizationId}/members',
    authRequired: true,
    paramTypes: {
      values: 'data',
    },
  },
  reSendActivationEmailInternal: {
    method: 'POST',
    url: '/accounts/organizations/{organizationId}/members/{profileId}/activation/re-send',
    authRequired: true,
  },
  resetPasswordInternal: {
    method: 'POST',
    url: '/accounts/profiles/{profileId}/reset-password',
    authRequired: true,
    paramTypes: {
      'If-Match': 'header',
    },
  },
  reSendActivationEmailOwner: {
    method: 'POST',
    url: '/organization/members/{profileId}/activation/re-send',
    authRequired: true,
  },
  resetPassword: {
    method: 'POST',
    url: '/organization/members/{profileId}/reset-password',
    authRequired: true,
    paramTypes: {
      'If-Match': 'header',
    },
  },
  getOrganizationTrustedSupplierListInternal: {
    url: '/accounts/organizations/{organizationId}/trusted-suppliers',
    method: 'GET',
    authRequired: true,
  },
  getOrganizationPossibleTrustedSupplierListInternal: {
    url: '/accounts/organizations/{organizationId}/possible-suppliers',
    method: 'GET',
    authRequired: true,
  },
  addOrganizationTrustedSupplierInternal: {
    url: '/accounts/organizations/{organizationId}/trusted-suppliers',
    method: 'POST',
    authRequired: true,
    paramTypes: {
      values: 'data',
    },
  },
  removeOrganizationTrustedSupplierInternal: {
    url: '/accounts/organizations/{organizationId}/trusted-suppliers/{supplierId}',
    method: 'DELETE',
    authRequired: true,
  },
  getPartnerAirlinesInternal: {
    url: '/accounts/organizations/{organizationId}/partner-airlines',
    method: 'GET',
    authRequired: true,
  },
  getOrganizationMembers: {
    url: '/organization/members',
    method: 'GET',
    authRequired: true,
    paramTypes: {
      status: 'query',
    },
  },
  getTrustedSupplierOrgList: {
    method: 'GET',
    authRequired: true,
    url: '/airline/organization/possible-suppliers',
  },
  addTrustedSupplier: {
    method: 'POST',
    authRequired: true,
    url: '/airline/organization/trusted-suppliers',
    paramTypes: {
      values: 'data',
    },
  },
  removeTrustedSupplier: {
    url: 'airline/organization/trusted-suppliers/{id}',
    method: 'DELETE',
    authRequired: true,
  },
  getTrustedSuppliers: {
    method: 'GET',
    url: '/airline/organization/trusted-suppliers',
    authRequired: true,
  },
  getOrganizationMemberDetail: {
    url: '/organization/members/{profileId}',
    method: 'GET',
    authRequired: true,
  },
  updateOrganizationMemberDetails: {
    method: 'PATCH',
    url: '/organization/members/{profileId}',
    authRequired: true,
    paramTypes: {
      values: 'data',
      'If-Match': 'header',
    },
  },
  deleteOrganizationMemberPendingEmail: {
    url: '/organization/members/{profileId}/email-change',
    method: 'DELETE',
    authRequired: true,
    paramTypes: {
      'If-Match': 'header',
    },
  },
  deleteOwnershipTransfer: {
    method: 'DELETE',
    url: '/organization/members/{profileId}/owner-change',
    authRequired: true,
    paramTypes: {
      'If-Match': 'header',
    },
  },
  createUser: {
    method: 'POST',
    url: '/organization/members',
    authRequired: true,
    paramTypes: {
      values: 'data',
    },
  },
  getOrganizationMemberRoles: {
    method: 'GET',
    url: '/organization/subsystem-roles',
    authRequired: true,
  },
  getDeveloperPartnerAirlines: {
    url: '/developer/organization/partner-airlines',
    method: 'GET',
    authRequired: true,
  },
  uploadOrganizationLogo: {
    url: '/organization/logo',
    method: 'POST',
    authRequired: true,
    paramTypes: {
      file: 'data',
    },
  },
  createAirlineOrganization: {
    url: '/accounts/airlines',
    method: 'POST',
    authRequired: true,
    paramTypes: {
      values: 'data',
    },
  },
  getUserActivationData: {
    method: 'GET',
    url: '/profile-activation',
    authRequired: true,
    paramTypes: {
      token: 'query',
    },
  },
  activateUser: {
    method: 'POST',
    url: '/profile-activation',
    authRequired: true,
    paramTypes: {
      token: 'query',
      values: 'data',
    },
  },
  getUserActivationDataPublic: {
    method: 'GET',
    url: '/profile-activation/public',
    authRequired: false,
    paramTypes: {
      token: 'query',
    },
  },
  activateUserPublic: {
    method: 'POST',
    url: '/profile-activation/public',
    authRequired: false,
    paramTypes: {
      token: 'query',
      values: 'data',
    },
  },
  registerDeveloperUser: {
    method: 'POST',
    url: '/developers',
    authRequired: false,
    paramTypes: {
      values: 'data',
    },
  },
  getInternalOrganisation: {
    url: '/internal/organizations/{organizationId}',
    method: 'GET',
    authRequired: true,
  },
  updateInternalOrganisation: {
    url: '/internal/organizations/{organizationId}',
    method: 'PATCH',
    authRequired: true,
    paramTypes: {
      values: 'data',
      'If-Match': 'header',
    },
  },
  verifyEmailChange: {
    method: 'POST',
    url: '/email-change-activation',
    authRequired: false,
    paramTypes: {
      token: 'query',
    },
  },
  confirmPendingOwnership: {
    method: 'POST',
    url: '/owner-change-activation',
    authRequired: true,
    paramTypes: {
      token: 'query',
    },
  },
  getMainRoles: {
    url: '/organization/main-roles',
    method: 'GET',
    authRequired: true,
  },
  getMainRolesOrganization: {
    url: '/accounts/main-roles',
    method: 'GET',
    authRequired: true,
    paramTypes: {
      organizationType: 'query',
    },
  },
  getOrganizationSettings: {
    url: '/accounts/organizations/{organizationId}/settings',
    method: 'GET',
    authRequired: true,
  },
  patchOrganizationSettings: {
    url: '/accounts/organizations/{organizationId}/settings',
    method: 'PATCH',
    authRequired: true,
    paramTypes: {
      'If-Match': 'header',
      values: 'data',
    },
  },
  changePassword: {
    url: '/profile/password',
    method: 'POST',
    authRequired: true,
    paramTypes: {
      values: 'data',
    },
  },
  fetchNotificationSettings: {
    url: '/profile/notification-settings',
    method: 'GET',
    authRequired: true,
  },
  saveNotificationSettings: {
    url: '/profile/notification-settings',
    method: 'PATCH',
    authRequired: true,
    paramTypes: {
      // 'If-Match': 'header',
      values: 'data',
    },
  },
  getExportInfo: {
    method: 'GET',
    url: '/history/exports/info',
    authRequired: true,
    paramTypes: {
      organizationId: 'query',
    },
  },
  cancelExport: {
    method: 'POST',
    url: '/history/exports/{exportId}/cancel',
    authRequired: true,
  },
  getActiveExports: {
    method: 'GET',
    url: '/history/exports',
    authRequired: true,
  },
  confirmDownloadExport: {
    method: 'POST',
    url: '/history/exports/{exportId}/confirm-download',
    authRequired: true,
  },
  exportHistory: {
    method: 'POST',
    url: '/history/exports',
    authRequired: true,
    paramTypes: {
      fromDate: 'query',
      toDate: 'query',
      organizationId: 'query',
    },
  },
  saveLanguageAndRegionSettings: {
    url: '/profile/preferences',
    method: 'PATCH',
    authRequired: true,
    paramTypes: {
      values: 'data',
    },
  },
  getLanguages: {
    method: 'GET',
    url: '/profile/preferences/languages',
    authRequired: true,
  },
  getTimezones: {
    method: 'GET',
    url: '/profile/preferences/timezones',
    authRequired: true,
  },
};

/**
 * @type {Record<keyof serviceConfig, Function>}
 */
const services = {};

export const initServices = (organizationInfo, keycloak) => {
  Object.assign(services, createService(serviceConfig, organizationInfo, keycloak));
};

export default services;
