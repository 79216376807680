/* eslint-disable no-useless-catch */
import services from 'api/services';
import roles from '@control-tower/aerq-navigation-library/dist/config/roles.json';
import { OrganizationType } from 'common/enums';

export const SET_ITEMS = 'SET_ITEMS';
export const SET_FAILED_ORG_ID = 'SET_FAILED_ORG_ID';
export const SET_ITEM_DOWNLOADED = 'SET_ITEM_DOWNLOADED';
export const SET_ACTIVE_EXPORT_CHECKED_ON_INIT = 'SET_ACTIVE_EXPORT_CHECKED_ON_INIT';
export const SET_ITEM_FAILED = 'SET_ITEM_FAILED';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const REMOVE_DOWNLOADED_ITEM = 'REMOVE_DOWNLOADED_ITEM';

export const setItems = (payload) => ({ payload, type: SET_ITEMS });

export const setFailedOrgId = (payload) => ({ payload, type: SET_FAILED_ORG_ID });

export const setItemDownloaded = (payload) => ({ payload, type: SET_ITEM_DOWNLOADED });

export const setActiveExportsCheckedOnInit = () => ({ type: SET_ACTIVE_EXPORT_CHECKED_ON_INIT });

export const removeDownloadedItem = (payload) => ({ payload, type: REMOVE_DOWNLOADED_ITEM });

export const setItemFailed = (payload) => ({ payload, type: SET_ITEM_FAILED });

export const cancelExport = async ({ exportId }) => {
  const { payload } = await services.cancelExport({ exportId });

  return payload;
};

export const getExportInfo = async ({ organizationId }) => {
  try {
    const { payload } = await services.getExportInfo({ organizationId });

    return payload;
  } catch (err) {
    throw err;
  }
};

export const getActiveExports = async (params) => {
  const { dispatch, permissions, organizationType } = params.length ? params[0] : params;

  if (
    (permissions &&
      ((organizationType === OrganizationType.INTERNAL && permissions.has(roles.viewOrgHistoryInternal)) ||
        ([OrganizationType.DEVELOPER, OrganizationType.AIRLINE].includes(organizationType) &&
          permissions.has(roles.viewOrganizationOrgHistory)))) ||
    !permissions
  ) {
    try {
      const { payload } = await services.getActiveExports();
      if (dispatch) {
        dispatch(setItems(payload));
      } else {
        // cancel all active exports if we have when portal loaded
        payload.forEach((item) => {
          cancelExport({ exportId: item.id });
        });
      }

      return payload;
    } catch (err) {
      throw err;
    }
  }
  return null;
};

export const confirmDownloadExport = async ({ exportId }) => {
  const { payload } = await services.confirmDownloadExport({ exportId });

  return payload;
};

export const exportHistory = async ({ organizationId, fromDate, toDate }) => {
  try {
    const { payload } = await services.exportHistory({ organizationId, fromDate, toDate });

    return payload;
  } catch (err) {
    throw err;
  }
};
