import { useEffect, lazy, useCallback } from 'react';
import {
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM,
  KEYCLOAK_URL,
  USER_MANAGEMENT_WEB_API_URL,
  NOTIFICATION_SERVICE_URL,
} from 'common/constants';
import NoMatch from 'view/components/NoMatch';
import ErrorDialog from 'view/components/error/ErrorDialog';
import ErrorBoundary from 'view/components/error/ErrorBoundary';
import DesignSystem from 'theme/design_system/designSystem';
import Messages from 'view/components/Messages';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import ScrollToTop from 'common/scrollToTop';
import UnauthorizedComponent from 'view/components/error/UnauthorizedComponent';
import { useMessagesDispatch } from 'state/Messages/context';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import axios from 'axios';
import { UserProvider } from 'state/User/context';
import { AccountsProvider } from 'state/Accounts/context';
import {
  AERQNavigation,
  AuthorizationError,
  OrganizationSelector,
  ProfileChangedFlyout,
  DemoModeFlyout,
  AdminInitializationPopup,
} from '@control-tower/aerq-navigation-library';
import { usePreferencesState } from '@control-tower/aerq-navigation-library/dist/auth/state/Preferences/context';
import { useAuthConfState } from '@control-tower/aerq-navigation-library/dist/auth/state/Auth/authContext';
import setupMessageHook from 'api/interceptors/messages';
import { initServices } from 'api/services';
import { VersionInfoProvider } from 'state/VersionInfo/context';
import { hideLoadingOverlay, hideNeutralLoadingOverlay, showLoadingOverlay } from 'utils/toggleLoadingOverlay';
import useNavigate from 'common/hooks/useNavigate';
import { showErrorDialog } from 'state/Messages/actions';
import UserRegistration from 'view/User/UserRegistration';
import ThemeWrapper from 'view/components/ThemeWrapper';
import { DownloaderProvider } from 'state/components/downloader/context';

const UsersView = lazy(() => import(/* webpackChunkName: 'UsersRoot' */ './view/User'));
const AccountsView = lazy(() => import(/* webpackChunkName: 'AccountsView' */ './view/Accounts'));
const UserActivation = lazy(() => import(/* webpackChunkName: 'UserActivation' */ './view/User/UserActivation'));
const DeveloperUserRegistration = lazy(() =>
  import(/* webpackChunkName: 'DeveloperUserRegistration' */ './view/User/DeveloperUserRegistration')
);
const DeveloperUserRegistrationSuccess = lazy(() =>
  import(/* webpackChunkName: 'DeveloperUserRegistrationSuccess' */ './view/User/DeveloperUserRegistrationSuccess')
);
const UserActivationJoinOrg = lazy(() =>
  import(/* webpackChunkName: 'UserActivationJoinOrg' */ './view/User/UserActivationJoinOrg')
);
const UserActivationJoinOrgSuccess = lazy(() =>
  import(/* webpackChunkName: 'UserActivationJoinOrgSuccess' */ './view/User/UserActivationJoinOrgSuccess')
);
const EmailChangeSuccess = lazy(() =>
  import(/* webpackChunkName: 'UserActivationSuccess' */ './view/User/EmailChange')
);

const PendingOwnershipConfirmation = lazy(() =>
  import(/* webpackChunkName: 'PendingOwnershipConfirmation' */ './view/User/PendingOwnershipConfirmation')
);

const useStyles = DesignSystem.organisms.App();

const configAxios = (lang) => {
  const setHeader = (config) => {
    return { ...config, headers: { ...config.headers, 'Accept-Language': lang } };
  };
  const handleError = (error) => {
    return Promise.reject(error);
  };
  axios.interceptors.request.use(setHeader, handleError);
};

// if (typeof serviceMessages.status !== 'undefined' && typeof serviceMessages.messages !== 'undefined') {
//   messagesDispatch(showErrorDialog(serviceMessages.status, serviceMessages.messages));
// } else if (typeof serviceMessages.data !== 'undefined') {
//   handleMessages({ responseData: serviceMessages.data, dispatcher: messagesDispatch, i18n });
// }

const App = () => {
  const messagesDispatch = useMessagesDispatch();
  const translation = useTranslation();

  useEffect(() => {
    // setup service interceptor
    setupMessageHook(messagesDispatch, translation);

    hideNeutralLoadingOverlay();
    showLoadingOverlay();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  const handleAERQNavigationError = useCallback(
    (error) => {
      hideLoadingOverlay();

      if (error instanceof AuthorizationError) {
        messagesDispatch(showErrorDialog(401, [{ code: 'NO_PERMISSION' }]));

        return;
      }

      if (error.response) {
        const { data, status } = error.response;
        messagesDispatch(showErrorDialog(status, data.messages));
        return;
      }

      if (error.request) {
        messagesDispatch(showErrorDialog(404));

        return;
      }

      messagesDispatch(showErrorDialog());
    },
    [messagesDispatch]
  );

  const navigateTo = useNavigate();

  return (
    <AERQNavigation
      keycloakOptions={{
        url: KEYCLOAK_URL,
        realm: KEYCLOAK_REALM,
        clientId: KEYCLOAK_CLIENT_ID,
      }}
      onBeforeInit={() => {
        initServices(null, null);
      }}
      onAfterSelectProfile={(orgInfo, keycloak) => {
        initServices(orgInfo, keycloak);
      }}
      navigateTo={navigateTo}
      notificationServiceURL={NOTIFICATION_SERVICE_URL}
      userManagementWebApiURL={USER_MANAGEMENT_WEB_API_URL}
      publicRoutes={['/activate-user', '/sign-up-developer', '/sign-up', '/succesful-sign-up', '/email-verification']}
      skipOrgSelectorRoutes={['/join-organization', '/activate-user-success', '/confirm-ownership']}
      ErrorComponent={null}
      onError={handleAERQNavigationError}
    >
      <MainContentWithTheme />
    </AERQNavigation>
  );
};

const MainContentWithTheme = () => {
  const navigateTo = useNavigate();

  return (
    <ThemeWrapper>
      <Messages />
      <ProfileChangedFlyout />
      <DemoModeFlyout />
      <AdminInitializationPopup clientId={KEYCLOAK_CLIENT_ID} navigateTo={navigateTo} />
      <MainContent />
    </ThemeWrapper>
  );
};

const MainContent = () => {
  // We are using theme here, so ThemeProvider has to be in parents!
  const classes = useStyles();

  const authState = useAuthConfState();

  useEffect(() => {
    hideLoadingOverlay();
  }, []);

  const { preferences } = usePreferencesState();

  useEffect(() => {
    const lang = preferences.language.toLowerCase();
    i18n.changeLanguage(lang);
    localStorage.setItem('appLanguage', lang);

    configAxios(lang);
  }, [preferences.language]);

  const navigateTo = useNavigate();
  const location = useLocation();

  return (
    <UserProvider>
      <AccountsProvider>
        <VersionInfoProvider>
          <DownloaderProvider>
            <ErrorBoundary>
              <ErrorDialog>
                <div className={classes.app}>
                  <ScrollToTop />
                  <Routes>
                    <Route path="/unauthorized" element={<UnauthorizedComponent />} />
                    <Route
                      path="/organization-selector"
                      element={
                        <OrganizationSelector
                          onAfterSelectProfile={(orgInfo, keycloak) => {
                            initServices(orgInfo, keycloak);
                          }}
                          userManagementWebApiURL={USER_MANAGEMENT_WEB_API_URL}
                          notificationServiceURL={NOTIFICATION_SERVICE_URL}
                          clientId={KEYCLOAK_CLIENT_ID}
                          navigateTo={navigateTo}
                          Link={Link}
                          location={location}
                        />
                      }
                      authData={authState.authData}
                      requiredRole={null}
                    />
                    <Route path="/user/*" element={<UsersView />} />
                    <Route path="/internal/accounts/*" element={<AccountsView />} />
                    <Route path="/sign-up-developer" element={<DeveloperUserRegistration />} />
                    <Route path="/sign-up" element={<UserRegistration />} />
                    <Route path="/successful-sign-up" element={<DeveloperUserRegistrationSuccess />} />
                    <Route path="/join-organization" element={<UserActivationJoinOrg />} />
                    <Route path="/join-organization-success" element={<UserActivationJoinOrgSuccess />} />
                    <Route path="/activate-user/*" element={<UserActivation />} />
                    <Route path="/email-verification" element={<EmailChangeSuccess />} />
                    <Route path="/confirm-ownership" element={<PendingOwnershipConfirmation />} />

                    <Route path="/" />
                    <Route path="*" element={<NoMatch />} />
                  </Routes>
                </div>
              </ErrorDialog>
            </ErrorBoundary>
          </DownloaderProvider>
        </VersionInfoProvider>
      </AccountsProvider>
    </UserProvider>
  );
};

export default App;
