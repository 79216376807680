import { actions } from './actions';

const initialState = {
  members: [],
  memberDetail: {},
  trustedSuppliers: [],
  partnerAirlines: [],
  registeredDeveloperUser: {},
  userActivationData: {},
  activatedUser: {},
  notificationSettings: [],
  languages: [],
  timeZones: [],
};

const reducer = (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case actions.GET_ORGNIZATION_MEMBERS: {
      return { ...state, members: [...payload] };
    }
    case actions.SET_TRUSTED_SUPPLIERS: {
      return { ...state, trustedSuppliers: [...payload] };
    }
    case actions.SET_PARTNER_AIRLINES: {
      return { ...state, partnerAirlines: [...payload] };
    }
    case actions.REGISTER_DEVELOPER_USER:
      return { ...state, registeredDeveloperUser: payload };
    case actions.SET_USER_ACTIVATION_DATA:
      return { ...state, userActivationData: { ...payload } };
    case actions.SET_ACTIVATED_USER:
      return { ...state, activatedUser: payload };
    case actions.SET_NOTIFICATION_SETTINGS: {
      return { ...state, notificationSettings: payload };
    }
    case actions.SET_LANGUAGES: {
      return { ...state, languages: [...payload.languages] };
    }
    case actions.SET_TIMEZONES: {
      return { ...state, timeZones: payload };
    }
    default:
      return state;
  }
};

export { initialState, reducer };
