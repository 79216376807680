import { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Loadingindicator, RoutePromptConfirmDialog } from '@control-tower/aerq-ui-library';
import App from 'App';
import { MessagesProvider } from 'state/Messages/context';

const Root = () => (
  <Suspense fallback={<Loadingindicator />}>
    <MessagesProvider>
      <Router getUserConfirmation={(message, callback) => RoutePromptConfirmDialog(message, callback)}>
        <App />
      </Router>
    </MessagesProvider>
  </Suspense>
);

export default Root;
