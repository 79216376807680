const UserSettingsOrganism = (theme) => ({
  content: {
    padding: `${theme.spacing(8)} 0 0 0`,
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: theme.spacing(5),
  },
  notificationItem: {
    display: 'flex',
    marginBottom: theme.spacing(5),
    '& .MuiButtonBase-root': {
      padding: 0,
    },
  },
  checkedCircle: {
    paddingTop: 6,
    '& svg path': {
      stroke: theme.palette.grey[400],
    },
  },
  checkBox: {
    paddingTop: 2,
    '& > .Mui-checked > span > svg > rect': {
      fill: theme.palette.primary?.main,
    },
  },
  notificationItemTexts: {
    marginLeft: theme.spacing(3),
  },
  notificationItemTitle: {
    fontSize: theme.fontSizes.Normal,
    '& strong': {
      ...theme.fontWeights.Bold,
    },
  },
  notificationItemDescription: {
    fontSize: theme.fontSizes.Small,
    lineHeight: '24px',
    marginTop: theme.spacing(1),
    color: theme.palette.grey[600],
  },
  notificationDynamicItemDescription: {
    fontSize: theme.fontSizes.Small,
    lineHeight: '24px',
    marginTop: theme.spacing(1),
    color: theme.palette.common.black,
  },
  field: {
    marginBottom: theme.spacing(5),
  },
  fieldWrapper: {
    maxWidth: 690,
    flexShrink: 1,
    overflow: 'visible',
    '& $field': {
      width: 424,
      marginTop: 0,
    },
    '& $field ~ $field': {
      marginTop: theme.spacing(5),
    },
  },
  formActions: {
    '& h2': {
      marginTop: theme.spacing(2.5),
    },
  },
  languageAndRegion: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(6),
  },
  selectField: {
    maxWidth: 424,
    marginBottom: theme.spacing(4),
    '&:last-child': {
      marginBottom: 0,
    },
  },
});

export { UserSettingsOrganism as default };
