const PasswordChangeOrganism = (theme) => ({
  content: {
    padding: `${theme.spacing(8)} 0 0 0`,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.spacing(2.5),
  },
  field: {
    marginBottom: theme.spacing(5),
  },
  fieldWrapper: {
    marginTop: theme.spacing(3.75),
    width: 424,
    flexShrink: 1,
    overflow: 'visible',
    '& $field': {
      width: 424,
      marginTop: 0,
    },
    '& $field ~ $field': {
      marginTop: theme.spacing(5),
    },
    '& $fieldInnerWrapper': {
      padding: [[theme.spacing(1), 0]],
    },
    '& input::-ms-reveal, input::-ms-clear': {
      display: 'none',
    },
  },
  modalInnerTitle: {
    marginBottom: theme.spacing(2),
  },
  callout: {
    marginBottom: 40,
    '& strong': {
      ...theme.fontWeights.Bold,
    },
  },
  calloutButton: {
    marginTop: 20,
  },
  description: {
    fontSize: 18,
  },
});

export { PasswordChangeOrganism as default };
