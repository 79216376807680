const OrganizationInformationMolecule = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  form: {
    width: '424px',
    maxWidth: '80%',
  },
  field: {
    width: 424,
    marginBottom: theme.spacing(5),
  },
  uploader: {
    '& .label-text': {
      marginTop: 0,
      marginLeft: theme.spacing(0.5),
    },
    '& img': {
      width: 'auto',
    },
  },
  selectField: {
    '&$field > div': {
      width: '100%',
    },
  },
  btnSave: {
    width: 86,
    flexShrink: 0,
  },
  viewOnly: {
    '& .rowCell': {
      marginTop: 0,
    },
    '& .rowCell ~ .rowCell': {
      marginTop: theme.spacing(7),
    },
  },
});

export { OrganizationInformationMolecule as default };
