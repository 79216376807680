const showLoadingOverlay = () => {
  document.getElementById('loading-mask').style.display = 'block';
  document.getElementById('loading-mask-icon').style.display = 'block';
};

const hideLoadingOverlay = () => {
  document.getElementById('loading-mask').style.display = 'none';
  document.getElementById('loading-mask-icon').style.display = 'none';
};

const hideNeutralLoadingOverlay = () => {
  document.getElementById('neutral-loading-mask').style.display = 'none';
};

export { showLoadingOverlay, hideLoadingOverlay, hideNeutralLoadingOverlay };
