import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from '@control-tower/aerq-ui-library';
import { KeycloakContext } from '@control-tower/aerq-navigation-library';

/**
 * If the user doesn't have any menu to show regarding her/his roles, then this page will be shown
 * @param {*} props
 */
const UnauthorizedComponent = () => {
  const { t } = useTranslation();

  const [confirmOpened, setConfirmOpened] = useState(true);
  const keycloak = useContext(KeycloakContext);

  const handleConfirmYes = () => {
    setConfirmOpened(false);
    keycloak.logout({ redirectUri: window.location.origin });
  };

  return (
    <ConfirmDialog
      isOpen={confirmOpened}
      title={t('unauthorized.title')}
      content={t('unauthorized.message')}
      positiveBtnText={t('header.logout')}
      negativeBtnText=""
      handlePositiveCh={handleConfirmYes}
      handleNegativeCh={() => {}}
      dataTestId="permission-denied-confirmdialog"
    />
  );
};

export default UnauthorizedComponent;
