const AppOrganism = (theme) => ({
  app: {
    backgroundColor: theme.background.common,
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    '& > div': {
      flex: '1 0 auto',
    },
  },
});
export default AppOrganism;
